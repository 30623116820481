import { defineMessages } from 'react-intl';

export default defineMessages({
  lock: 'Lock',
  rdnt: 'RDNT',
  tokenLockTitle: 'Platform Fees Paid to Lockers',
  tokenLockDescription:
    'Platform Fees such as interest, early exit penalties and liquidations are distributed to RDNT Lockers.',
  titleDescription:
    'Revenue generated from borrowing interest and flash loan fees. 50% of borrowing interest is paid proportionally to users who lock RDNT.',
});
