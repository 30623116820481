import { useRdntBalanceContext } from 'libs/wallet-balance-provider/RdntBalanceProvider';
import Value from 'components/basic/Value';
import staticStyles from './style';
import rdntIcon from 'images/radiant/radiant-token-color-v2.svg';

export default function RdntBalance() {
  const { walletBalance } = useRdntBalanceContext();

  return (
    <div className="RdntBalance">
      <img src={rdntIcon} alt="RDNT" />
      <Value value={Number(walletBalance)} maximumValueDecimals={2} minimumValueDecimals={2} />
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
