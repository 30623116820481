import { useIntl } from 'react-intl';

import TextWithTooltip from 'components/TextWithTooltip';
import { CompactNumber } from 'components/basic/CompactNumber';
import messages from './messages';
import staticStyles from './style';

interface TotalSupplyProps {
  value: number;
  titleKey: 'token' | 'lockApr' | 'pool2Apr';
  descriptionKey: 'tokenDescription' | 'lockAprDescription' | 'pool2AprDescription';
  text?: string;
  isPercent?: boolean;
}

export default function TotalSupply({
  titleKey,
  descriptionKey,
  value,
  text,
  isPercent = false,
}: TotalSupplyProps) {
  const intl = useIntl();

  const showFullNum = isPercent && value < 1000000;
  const minimumFractionDigits = isPercent && value > 100 ? 0 : 2;

  return (
    <div className="TotalSupply">
      <div className="TotalSupply__title-container">
        <p>{intl.formatMessage(messages[titleKey])}</p>
        <TextWithTooltip text={''} iconSize={14} id={titleKey}>
          <p>{intl.formatMessage(messages[descriptionKey])}</p>
        </TextWithTooltip>
      </div>

      <h2>
        {!isPercent && '$ '}
        <CompactNumber
          value={value}
          maximumFractionDigits={isPercent ? 2 : 3}
          minimumFractionDigits={minimumFractionDigits}
          showFullNum={showFullNum}
        />
        {isPercent && ' %'}
      </h2>

      {text && <p>{text}</p>}

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .TotalSupply {
          color: #131313;
        }
      `}</style>
    </div>
  );
}
