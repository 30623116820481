import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .TotalMarketsSize {
    padding-right: 10px;

    @include respond-to(md) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      padding: 15px 10px;
    }

    &__title-container {
      display: flex;
      align-items: flex-start;

      .modal-text {
        margin-bottom: 8px !important;
      }
    }

    p {
      font-size: $large;
      margin-bottom: 5px;
    }

    &--big {
      font-family: 'Roboto';
      white-space: nowrap;
      font-size: 45px;
      margin-top: 12px;
    }
  }
`;

export default staticStyles;
