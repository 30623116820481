import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Manage RDNT Locks and Vests',
  subTitle:
    'Lock RDNT to share platform fees or lock it for 4 weeks to receive platform fees AND exit penalties.',
  walletDashboard: 'Your Wallet Dashboard',
  lockedStakedGeist: 'Locked + Vesting RDNT',
  dailyRevenue: 'Your Daily revenue',
  weeklyRevenue: 'Your Weekly revenue',
  dailyPlatformFees: 'Your Daily Platform Fees',
  dailyPenaltyFees: 'Your Daily Penalty Fees',

  incentivesPerMonth: 'RDNT per month',
  cooldownPeriod: 'Cooldown period',
  claim: 'Claim',

  noWalletConnect: 'Please connect a wallet',
  noWalletConnectDescription: 'We couldn’t detect a wallet. Connect a wallet to stake.',
  stakingAPY: 'Staking APR',
  currentMaxSlashing: 'Current max. slashing',
  days: 'Days',
  seconds: 'Seconds',

  helpIconLockedStaked:
    'RDNT rewards can be vested for 28 days to receive the full amount. While vesting, you earn 50% of platform revenue from borrowers paying interest. You can always exit early from a vest for a 50% penalty.',

  helpIconDailyFeesTitle: 'Daily platform fees are derived from two mechanisms:',
  helpIconDailyFees1:
    '1. 50% of the platform fees from borrowing interest are distributed to users who stake or lock RDNT.',
  helpIconDailyFees2:
    '2. All of the early exit penalty fees from vesting RDNT are distributed to locked RDNT holders.',
  helpIconDailyRevenue:
    'Your daily revenue earned from locking and/or vesting RDNT. Revenue includes both platform and penalty fees when applicable.',

  vestLock: '1-Click Vest + Lock',
  withdrawLock: '1-Click Withdraw + Lock',

  vestLockDescription: 'Earn {lockingApr}% by locking your RDNT now.',
  vestLockTooltip: 'Instantly Vest & Exit your pending RDNT to start earning the Lock APR',
  startVesting: 'Start Vesting',
});
