import { FormEvent, ReactNode, useState, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { ChainId } from '@aave/contract-helpers';
import { valueToBigNumber, EthereumTransactionTypeExtended } from '@aave/protocol-js';

import { useUserWalletDataContext } from 'libs/web3-data-provider';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import TxEstimation from 'components/TxEstimation';
import Caption from 'components/basic/Caption';
import AmountField from 'components/fields/AmountField';
import SelectChainField from 'components/fields/SelectChainField';
import RiskBar from 'components/basic/RiskBar';
import DefaultButton from 'components/basic/DefaultButton';
import ConnectButton from 'components/ConnectButton';
import { getNetworkConfig } from 'helpers/config/markets-and-network-config';
import { CHAIN_INFO, CHAINS, CHAIN_ID_TO_NETWORK } from 'ui-config/chains';
import messages from './messages';
import staticStyles from './style';
import sgLogo from 'images/stargate.svg';

interface BorrowFormProps {
  title?: string;
  description?: string | ReactNode;
  maxAmount?: string | number;
  amountFieldTitle?: string;
  currencySymbol: string;
  onSubmit: (amount: string, chainId: number, max?: boolean) => void;
  withRiskBar?: boolean;
  submitButtonTitle?: string;
  absoluteMaximum?: boolean;
  className?: string;
  maxDecimals?: number;
  warning?: ReactNode;
  children?: ReactNode;
  getTransactionData?: (
    user: string
  ) => () => Promise<EthereumTransactionTypeExtended[]> | EthereumTransactionTypeExtended[];
}

export default function BorrowForm({
  title,
  description,
  maxAmount,
  amountFieldTitle,
  currencySymbol,
  onSubmit,
  withRiskBar,
  submitButtonTitle,
  absoluteMaximum,
  className,
  maxDecimals,
  warning,
  children,
  getTransactionData,
}: BorrowFormProps) {
  const intl = useIntl();
  const { chainId } = useProtocolDataContext();
  const { currentAccount } = useUserWalletDataContext();
  const config = getNetworkConfig(chainId);

  const [isMaxSelected, setIsMaxSelected] = useState(false);
  const [amount, setAmount] = useState('');
  const [selectChainId, setSelectChainId] = useState(
    config.isTestnet ? CHAIN_INFO.arbitrumTest.chainId : CHAIN_INFO.arbitrum.chainId
  );
  const [chainSelectVisible, setChainSelectVisible] = useState(false);
  const [error, setError] = useState('');

  const isStableWithoutDAI = useMemo(
    () => currencySymbol === 'USDC' || currencySymbol === 'USDT',
    [currencySymbol]
  );

  const handleAmountChange = useCallback(
    (newAmount: string) => {
      const newAmountValue = valueToBigNumber(newAmount);
      setError('');
      if (maxAmount && newAmountValue.gt(maxAmount)) {
        setAmount(maxAmount as string);
        return setIsMaxSelected(true);
      } else if (newAmountValue.isNegative()) {
        setAmount('0');
      } else {
        setAmount(newAmount);
      }
      setIsMaxSelected(false);
    },
    [maxAmount, setAmount, setIsMaxSelected]
  );

  const handleMaxButtonClick = useCallback(() => {
    setAmount(maxAmount as string);
    setIsMaxSelected(true);
    setError('');
  }, [maxAmount, setAmount, setIsMaxSelected, setError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!valueToBigNumber(amount).isNaN() && +amount !== 0) {
      return onSubmit(amount, selectChainId, absoluteMaximum && isMaxSelected);
    }

    setError(intl.formatMessage(messages.error));
  };

  return (
    <form onSubmit={handleSubmit} className={classNames('BorrowForm', className)}>
      {!!title && <Caption title={title} description={description} color="dark" />}

      <div className="BorrowForm__inner">
        {children}

        <AmountField
          title={amountFieldTitle}
          maxAmount={maxAmount}
          symbol={currencySymbol}
          maxDecimals={maxDecimals}
          value={amount}
          onChange={handleAmountChange}
          onMaxButtonClick={handleMaxButtonClick}
          error={error}
        />

        {[ChainId.mainnet].includes(chainId) && getTransactionData && (
          <TxEstimation getTransactionsData={getTransactionData} amount={amount} />
        )}

        {withRiskBar && (
          <RiskBar
            value={Number(amount)}
            onChange={handleAmountChange}
            maxAmount={maxAmount as string}
            currencySymbol={currencySymbol}
          />
        )}

        {isStableWithoutDAI && process.env.REACT_APP_ENABLE_X_CHAIN_BORROW === 'true' && (
          <div className="BorrowForm__select">
            <p>{intl.formatMessage(messages.selectLabel)}</p>

            <SelectChainField
              className="BorrowForm__select-field"
              visible={chainSelectVisible}
              setVisible={setChainSelectVisible}
              placeholder={intl.formatMessage(messages.selectChain)}
              value={CHAIN_ID_TO_NETWORK[selectChainId]}
            >
              {CHAINS.filter((chain) => chain.isTestnet === config.isTestnet).map((item) => (
                <button
                  className="BorrowForm__select-button"
                  type="button"
                  onClick={() => {
                    setSelectChainId(item.chainId);
                    setChainSelectVisible(false);
                  }}
                  disabled={
                    selectChainId === item.chainId || item.disabledTokens.includes(currencySymbol)
                  }
                  key={item.chainId}
                >
                  <img src={item.image} alt="network-icon" />
                  <span>{item.name}</span>
                </button>
              ))}
            </SelectChainField>

            <div className="BorrowForm__xchain-desc">
              <p>Bridging via</p>
              <img src={sgLogo} height="30" alt="sub-logo" />
              <a href="https://docs.radiant.capital/" target="_blank" rel="noreferrer">
                Learn more
              </a>
            </div>
          </div>
        )}

        {!!warning && <div className="BorrowForm__warning">{warning}</div>}
      </div>

      <div className="BorrowForm__buttons">
        {!currentAccount ? (
          <ConnectButton />
        ) : (
          <DefaultButton
            title={submitButtonTitle || intl.formatMessage(messages.continue)}
            mobileBig={true}
            size="big"
            type="submit"
            color="purple"
          />
        )}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </form>
  );
}
