import { Navigation } from 'components/menu/navigation';
import { isFeatureEnabled } from 'helpers/config/markets-and-network-config';
import messages from './messages';

export const moreMenuItems: Navigation[] = [
  {
    link: '/staking',
    title: messages.stake,
    isVisible: () => true,
    isSpanishMoreButton: true,
  },
  {
    link: 'https://app.sushi.com/swap',
    title: messages.buyRdnt,
    absolute: true,
    isBuyRDNT: true,
    isSpanishMoreButton: true,
  },
  {
    link: '/faucet',
    title: messages.faucet,
    isVisible: isFeatureEnabled.faucet,
  },
  {
    link: 'http://docs.radiant.capital',
    title: messages.docs,
    absolute: true,
  },
];

export const moreMenuExtraItems: Navigation[] = [];

export const moreMenuMobileOnlyItems: Navigation[] = [
  {
    link: '/faucet',
    title: messages.faucet,
    isVisible: isFeatureEnabled.faucet,
  },
  {
    link: 'http://docs.radiant.capital',
    title: messages.docs,
    absolute: true,
  },
];
