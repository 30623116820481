import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .TopStats {
    .TopStats__subText {
      .TokenIcon {
        display: none;
      }
    }
  }

  .Borrows {
    .TopStats__title {
      color: #6a0505;
    }
  }

  .health-factor .TokenIcon {
    display: none;
  }

  .ManageRadiantMain__revenue-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 300px;

    @include respond-to(sm) {
      margin-top: 20px;
      max-width: 100%;
    }
  }

  .green {
    .TopStats__title {
      color: green !important;
    }
  }

  .ManageRadiantMain__top {
    display: flex;
    gap: 30px;

    @include respond-to(sm) {
      flex-direction: column;
    }
  }

  .ContentWrapperWithTopLine__content {
    display: flex;
    justify-content: space-between;
    background: #ffffffeb;
  }

  .ManageRadiantMain__top-revenue {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    .GradientLine {
      margin: 0 30px;
    }
  }

  .ManageRadiantMain__top-fee {
    width: 50%;
    display: flex;
    flex-direction: column;

    .ContentWrapperWithTopLine__content {
      padding: 30px 35px;
    }

    .GradientLine {
      margin: 0 30px;
    }
  }

  .ContentWrapperWithTopLine__content {
    padding: 30px 35px;

    @include respond-to(sm) {
      flex-direction: column;
      padding: 0;
      margin: 0 20px;
    }
  }

  .Details_Button {
    .DefaultButton {
      width: 100%;
      font-size: 15px;
      margin-top: 15px;
    }
  }

  .Vesting_Button {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 15px;

    .description {
      display: flex;
      color: white;
      border-radius: 5px;
      border: 1px solid #008c79;
      background: #008c79ba;
      padding: 10px;
      font-size: 14px;
      font-weight: 500;
    }

    .button {
      margin: 0;
      width: 100%;
      font-size: $medium;
    }
  }

  .numberic {
    margin: auto;
    padding-left: 20px;
  }

  .DepositBorrowTopPanel {
    &__top-info {
      flex-direction: row;
      gap: 30px;
      display: flex;

      @include respond-to(md) {
        flex-direction: column;
      }
    }

    .data-grid {
      display: flex;
      gap: 5px;
      .col {
        p {
          margin-top: 5px;
        }
        &.numberic {
          p {
            font-family: 'open-sans', sans-serif;
            font-size: 12px;
            margin-top: 6px;
          }
        }

        .TextWithModal {
          margin-top: 5px;
          width: fit-content;
        }

        .TextWithModal__text {
          font-size: 14px;
        }
      }
    }

    &__left-info {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    &__right-info {
      width: 50%;
      min-width: 550px;

      @include respond-to(sm) {
        min-width: auto;
      }
    }

    &__left-info,
    &__right-info {
      @include respond-to(md) {
        width: 100%;
      }
    }

    &__topPanel {
      margin-bottom: 30px;
      @include respond-to(xl) {
        margin-bottom: 20px;
      }
      @include respond-to(lg) {
        margin-bottom: 10px;
      }
      @include respond-to(md) {
        margin-bottom: 30px;
      }
    }
    &__topPanelTransparent {
      background: transparent !important;
      border-radius: 0 !important;
      box-shadow: none !important;
    }

    &__topPanel-caption {
      display: flex;
      justify-content: space-between;
      p {
        font-size: $large;
        display: flex;
        flex-direction: column;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;
        i {
          font-style: normal;
          display: inline-block;
          padding: 10px 0 10px 20px;
          @include respond-to(md) {
            padding: 10px 0 10px 10px;
          }
        }

        &.DepositBorrowTopPanel__topPanelCaptionFull {
          width: 100%;
        }
      }
    }

    &__topPanel-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &__topPanelInfoCollapse {
      .DepositBorrowTopPanel__topPanel-inner {
        padding: 10px 20px;
        @include respond-to(md) {
          padding: 10px;
        }
      }
      .DepositBorrowTopPanel__topPanel-depositValues {
        flex-direction: row;
      }
    }
    &__topPanelNoUser {
      padding: 20px;
    }

    &__topPanel-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom-right-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
      transition: $transition;
      padding: 35px 30px;
      @include respond-to(sm) {
        padding: 5px 10px;
        flex-direction: column;
      }
    }

    &__deposit-top-panel {
      height: 100%;
    }

    &__topPanelInnerFull {
      width: 100% !important;
    }

    &__topPanel-values {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 225px;
      .Row {
        margin-right: 60px;
        @include respond-to(xl) {
          margin-right: 40px;
        }
      }
    }
    &__topPanelValuesCollapse {
      flex-direction: row;
    }
    &__topPanel-valuesInner {
      display: flex;
      margin-bottom: 15px;
      @include respond-to(xl) {
      }
      &:last-of-type {
        align-items: center;
      }
      .Row {
        width: 50%;
        margin-right: 0;
      }
    }
    &__topPanelValuesInnerCollapse {
      margin-bottom: 0;
      flex-wrap: wrap;
      .Row {
        width: auto;
        margin-right: 60px;
        @include respond-to(xl) {
          margin-right: 40px;
        }
      }
      &:last-of-type {
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        @include respond-to(md) {
          display: none;
        }
      }
    }
    .DepositBorrowTopPanel__buttonCollapse {
      width: 120px;
      min-height: 36px;
      font-size: $medium;
    }

    &__topPanel-bars {
      display: flex;
      align-items: center;
      justify-content: center;

      .CircleCompositionBar {
        @include respond-to(md) {
          margin-left: 0;
        }

        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    .Row.Row__column {
      .Row__title {
        font-size: $small;
        white-space: nowrap;
      }

      .Value__value,
      .Value__symbol,
      .ValuePercent__value,
      .ValuePercent__value span,
      .TokenIcon__dollar {
        font-size: $regular;
        font-weight: 600 !important;
      }

      .ValueWithSmallDecimals {
        font-weight: 600 !important;
      }

      .Row__title-inner {
        text-align: left;
      }
      .Row__content,
      .Value {
        align-items: flex-start;
        justify-content: flex-start;
      }
      .Row__content {
        text-align: left;
      }
    }
    .HealthFactor__column {
      text-align: left;
      .HealthFactor__percent {
        justify-content: flex-start;
      }
      .HealthFactor__no-value {
        text-align: left;
        justify-content: flex-start;
      }
    }
  }
`;

export default staticStyles;
