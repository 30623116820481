import { useEffect, useState } from 'react';
import { Asset } from '@aave/aave-ui-kit';
import { useIntl } from 'react-intl';
import staticStyles from './style';
import messsages from './messages';
import DefaultButton from 'components/basic/DefaultButton';
import TextWithTooltip from 'components/TextWithTooltip';
import { useApiDataProviderContext } from 'libs/api-data-provider/ApiDataProvider';
import { getAssetInfo, TokenIcon } from 'helpers/config/assets-config';
import Link from 'components/basic/Link';

export default function TokenLockPanel() {
  const { lockingAPRPerToken, lockingApr, dailyFees } = useApiDataProviderContext();
  const [lockedAssets, setLockedAssets] = useState<(Asset & { apr?: number })[]>([]);
  const intl = useIntl();

  useEffect(() => {
    const assets: (Asset & { apr?: number })[] = [];
    const tokenOrder: {
      [key: string]: number;
    } = {
      RDNT: 0,
      USDC: 1,
      DAI: 2,
      USDT: 3,
      WETH: 4,
      WBTC: 5,
    };
    Object.entries(lockingAPRPerToken).forEach(([tokenSymbol, apr]) => {
      // convert token symbol
      if (tokenSymbol !== 'RDNT') {
        tokenSymbol = tokenSymbol.substring(1);
      }
      const asset: Asset & { apr?: number } = getAssetInfo(tokenSymbol);
      asset.apr = (apr as number) * 100;
      assets.push(asset);
    });

    assets.sort((a: Asset & { apr?: number }, b: Asset & { apr?: number }) => {
      if (tokenOrder[a.symbol] > tokenOrder[b.symbol]) return 1;
      return -1;
    });
    setLockedAssets(assets);
  }, [lockingAPRPerToken]);

  return (
    <div className="TokenLockPanel">
      <div className="TokenLockPanel__child-wrapper total-info">
        <div className="TokenLockPanel__total-fee">
          <div className="TokenLockPanel__total-fee--title">
            <div>{intl.formatMessage(messsages.tokenLockTitle)}</div>
            <TextWithTooltip text={''} iconSize={14} id="TokenLockPanelFee">
              <p>{intl.formatMessage(messsages.titleDescription)}</p>
            </TextWithTooltip>
          </div>
          <b className="TokenLockPanel__total-fee--amount">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(dailyFees.totalPlatformFees || 0)}
          </b>
        </div>
        <p className="TokenLockPanel__lockTokenDescription">
          {intl.formatMessage(messsages.tokenLockDescription)}
        </p>
      </div>
      <div className="TokenLockPanel__child-wrapper">
        <p className="TokenLockPanel__subheader">
          <span className="TokenLockPanel__subheader--bold">Lock&nbsp;</span>
          <span className="TokenLockPanel__subheader--purple TokenLockPanel__subheader--bold TokenLockPanel__subheader--big-font">
            RDNT&nbsp;
          </span>
          and&nbsp;
          <span className="TokenLockPanel__subheader--bold">earn&nbsp;</span>
          <span className="TokenLockPanel__subheader--purple TokenLockPanel__subheader--bold TokenLockPanel__subheader--big-font">
            {(lockingApr * 100).toFixed(0)}%&nbsp;APR&nbsp;
          </span>
          paid in&nbsp;
        </p>

        <div className="TokenLockPanel__buttons-container">
          {lockedAssets.map((asset, index) => (
            <div className="TokenLockPanel__buttons-item" key={`TokenLockPanel-${index}`}>
              <TokenIcon
                tokenSymbol={asset.symbol}
                height={20}
                width={20}
                tokenFullName={asset.symbol}
                className="MarketTableItem__token"
              />
              <div className="TokenLockPanel__buttons-item--apr">{asset.apr?.toFixed(1)}%</div>
            </div>
          ))}
        </div>
        {/* <Link
          to={'/manage-radiant'}
          absolute={false}
          inNewWindow={true}
          color="dark"
          className="TokenLockPanel--full-width"
        >
          <DefaultButton
            title="Lock RDNT"
            color="purple"
            className="TokenLockPanel__lock-button"
            size="big"
          />
        </Link> */}
        <style jsx={true} global={true}>
          {staticStyles}
        </style>
      </div>
    </div>
  );
}
