import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .TopStats {
    h3 {
      display: flex;
      align-items: center;
      font-size: $regular;
      font-weight: 600;
      height: 35px;
      @include respond-to(sm) {
        height: 25px;
      }
    }

    &__title {
      font-family: 'Roboto';
      font-size: 34px;
      font-weight: 600;
    }

    &__subText {
      font-family: 'open-sans', sans-serif;
      font-size: 20px;
      font-weight: 400;
      color: grey;
      flex: 1 1 0 !important;
    }

    &__modal-text {
      font-weight: 400;
      padding-bottom: 16px;
    }
    .TokenIcon {
      float: left;
      margin-top: 9px;
    }
  }
`;

export default staticStyles;
