import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useThemeContext } from '@aave/aave-ui-kit';
import DashboardTable from 'modules/dashboard/components/DashboardTable';
import TableHeader from 'modules/dashboard/components/DashboardTable/TableHeader';
import DashboardMobileCardsWrapper from 'modules/dashboard/components/DashboardMobileCardsWrapper';
import BorrowItem from './BorrowItem';
import BorrowMobileCard from './BorrowMobileCard';
import messages from './messages';
import { BorrowTableItem } from './types';

interface BorrowDashboardTableProps {
  listData: BorrowTableItem[];
}

export default function BorrowDashboardTable({ listData }: BorrowDashboardTableProps) {
  const intl = useIntl();
  const { lg, md, sm } = useThemeContext();

  const head = [
    intl.formatMessage(messages.yourBorrows),
    intl.formatMessage(messages.secondTableColumnTitle),
    intl.formatMessage(messages.apyRowTitle),
    '',
  ];

  const colWidth = useMemo(
    () => (md ? ['100%', 380, 110, '100%'] : lg ? [245, 380, 110, '100%'] : [95, 125, 125, 125]),
    [md, lg]
  );

  return !sm ? (
    <>
      <TableHeader head={head} colWidth={colWidth} skipModals />

      <DashboardTable>
        {listData.map((item, index) => (
          <BorrowItem
            {...item}
            index={index}
            key={index}
            data-cy={`dashboardBorrowListItem_${item.reserve.symbol}`}
          />
        ))}
      </DashboardTable>
    </>
  ) : (
    <DashboardMobileCardsWrapper>
      {listData.map((item, index) => (
        <BorrowMobileCard {...item} key={index} />
      ))}
    </DashboardMobileCardsWrapper>
  );
}
