import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import BasicTable from 'components/BasicTable';
import TableColumn from 'components/BasicTable/TableColumn';
import TableHeaderButton from 'components/BasicTable/TableHeaderButton';
import messages from './messages';
import staticStyles from './style';

interface MarketTableProps {
  sortName: string;
  setSortName: (value: string) => void;
  sortDesc: boolean;
  setSortDesc: (value: boolean) => void;
  children: ReactNode;
}

export default function MarketTable({
  sortName,
  setSortName,
  sortDesc,
  setSortDesc,
  children,
}: MarketTableProps) {
  const intl = useIntl();

  const columns = [
    {
      title: messages.assets,
      sortKey: 'currencySymbol',
    },
    {
      title: messages.marketSize,
      sortKey: 'totalLiquidityInUSD',
    },
    {
      title: messages.totalBorrowed,
      sortKey: 'totalBorrowsInUSD',
    },
    {
      title: messages.depositAPY,
      sortKey: 'depositAPY',
    },
    {
      title: messages.borrowAPY,
      sortKey: 'variableBorrowRate',
    },
  ];

  return (
    <BasicTable
      className="MarketTable"
      headerColumns={
        <React.Fragment>
          {columns.map((column, index) => (
            <TableColumn
              className={classNames(
                'MarketTable__header-column',
                index === 0 || index === 5 ? 'MarketTable--flex-2' : 'MarketTable--flex-1'
              )}
              key={index}
            >
              <TableHeaderButton
                sortName={sortName}
                sortDesc={sortDesc}
                setSortName={setSortName}
                setSortDesc={setSortDesc}
                sortKey={column.sortKey}
                withSorting={true}
                title={intl.formatMessage(column.title)}
                size="small"
              />
            </TableColumn>
          ))}
        </React.Fragment>
      }
    >
      {children}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </BasicTable>
  );
}
