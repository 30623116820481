import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import TableItemWrapper from 'components/BasicTable/TableItemWrapper';
import TableColumn from 'components/BasicTable/TableColumn';
import Value from 'components/basic/Value';
import NoData from 'components/basic/NoData';
import LiquidityMiningCard from 'components/liquidityMining/LiquidityMiningCard';
import { getAssetInfo, TokenIcon } from 'helpers/config/assets-config';
import staticStyles from './style';

export interface MarketTableItemProps {
  id: string;
  underlyingAsset: string;
  currencySymbol: string;
  totalLiquidity: number;
  totalLiquidityInUSD: number;
  totalBorrows: number;
  totalBorrowsInUSD: number;
  depositAPY: number;
  avg30DaysLiquidityRate: number;
  variableBorrowRate: number;
  avg30DaysVariableRate: number;
  borrowingEnabled?: boolean;
  isFreezed?: boolean;
  isPriceInUSD?: boolean;
  rdntRewardsDepositApr?: number;
  rdntRewardsBorrowApr?: number;
}

export default function MarketTableItem({
  id,
  underlyingAsset,
  currencySymbol,
  totalLiquidity,
  totalLiquidityInUSD,
  totalBorrows,
  totalBorrowsInUSD,
  depositAPY,
  avg30DaysLiquidityRate,
  variableBorrowRate,
  avg30DaysVariableRate,
  borrowingEnabled,
  isFreezed,
  isPriceInUSD,
  rdntRewardsDepositApr,
  rdntRewardsBorrowApr,
}: MarketTableItemProps) {
  const history = useHistory();

  const asset = getAssetInfo(currencySymbol);
  const handleClick = useCallback(() => {
    history.push(`/reserve-overview/${underlyingAsset}-${id}`);
  }, [history, underlyingAsset, id]);

  return (
    <TableItemWrapper onClick={handleClick} className="MarketTableItem" withGoToTop={true}>
      <TableColumn className="MarketTableItem__column MarketTable--flex-2">
        <TokenIcon
          tokenSymbol={currencySymbol}
          height={35}
          width={35}
          tokenFullName={asset.name}
          className="MarketTableItem__token"
        />
      </TableColumn>
      <TableColumn className="MarketTableItem__column MarketTable--flex-1">
        <Value
          value={isPriceInUSD ? totalLiquidityInUSD : totalLiquidity}
          compact={true}
          maximumValueDecimals={2}
          withoutSymbol={true}
          tooltipId={`market-size-${asset.symbol}`}
          symbol={isPriceInUSD ? 'USD' : ''}
          tokenIcon={isPriceInUSD}
          className="MarketTableItem__value"
        />
      </TableColumn>
      <TableColumn className="MarketTableItem__column MarketTable--flex-1">
        {borrowingEnabled ? (
          <Value
            value={isPriceInUSD ? totalBorrowsInUSD : totalBorrows}
            compact={true}
            maximumValueDecimals={2}
            className="MarketTableItem__value"
            withoutSymbol={true}
            symbol={isPriceInUSD ? 'USD' : ''}
            tokenIcon={isPriceInUSD}
            tooltipId={`borrows-size-${asset.symbol}`}
          />
        ) : (
          <NoData color="dark" />
        )}
      </TableColumn>

      {!isFreezed && (
        <>
          <TableColumn className="MarketTableItem__column MarketTable--flex-1">
            <div className="MarketTableItem__column__wrapper">
              <LiquidityMiningCard
                value={depositAPY}
                thirtyDaysValue={avg30DaysLiquidityRate}
                liquidityMiningValue={rdntRewardsDepositApr}
                symbol={currencySymbol}
                type="deposit"
              />
            </div>
          </TableColumn>

          <TableColumn className="MarketTableItem__column MarketTable--flex-1">
            {borrowingEnabled && +variableBorrowRate >= 0 ? (
              <div className="MarketTableItem__column__wrapper">
                <LiquidityMiningCard
                  value={variableBorrowRate}
                  thirtyDaysValue={avg30DaysVariableRate}
                  liquidityMiningValue={rdntRewardsBorrowApr}
                  symbol={currencySymbol}
                  type="borrow-variable"
                />
              </div>
            ) : (
              <NoData color="dark" />
            )}
          </TableColumn>
        </>
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </TableItemWrapper>
  );
}
