import { useState } from 'react';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';

import BasicForm from 'components/forms/BasicForm';
import Value from 'components/basic/Value';
import LockConfirmation from '../LockConfirmation';
import iconLock from '../../images/icon-lock.svg';
import staticStyles from './style';

interface ContentItemLockProps {
  apr: number;
  maxAmount: string;
  currencySymbol: string;
  walletBalance: BigNumber;
  depositBalance: BigNumber;
  priceInMarketReferenceCurrency: string;
  onMainTxConfirmed: () => void;
}

function ContentItemLock({
  apr,
  maxAmount,
  currencySymbol,
  priceInMarketReferenceCurrency,
  walletBalance,
  depositBalance,
  onMainTxConfirmed,
}: ContentItemLockProps) {
  const [amount, setAmount] = useState<BigNumber | null>(null);

  return (
    <div className="ManageRadiant__content-lock">
      {!!amount ? (
        <LockConfirmation
          amount={amount}
          maxAmount={valueToBigNumber(maxAmount)}
          onMainTxConfirmed={onMainTxConfirmed}
          onAfterSuccessClick={() => {
            setAmount(null);
          }}
        />
      ) : (
        <>
          {/* <div className="ManageRadiant__form-controls">
            <BasicForm
              maxAmount={maxAmount}
              currencySymbol={currencySymbol}
              onSubmit={(amount) => setAmount(new BigNumber(amount))}
              submitButtonTitle="Lock"
            />
          </div>

          <div className="ManageRadiant__form-legend">
            <label className="ManageRadiant__input-label">Wallet Balance:</label>

            <Value
              className="ManageRadiant__value"
              symbol={currencySymbol}
              value={walletBalance.toString()}
              tokenIcon={false}
              subSymbol="USD"
              hideSuffix={true}
              maximumValueDecimals={2}
            />
          </div> */}

          {/* <div className="ManageRadiant__content-description">
            <p>Lock RDNT and earn platform fees and penalty fees in unlocked RDNT.</p>
            <p>
              Locked RDNT is subject to a 4 week lock (28 days) and will continue to earn fees after
              the locks expire if you do not withdraw.
            </p>
          </div> */}
          <div className="ManageRadiant__content-description">
            <p>
              <strong>ATTENTION</strong>: In order to minimize unlocks occurring AFTER the Radiant
              V2 migration, all NEW locks are disabled.
            </p>
            <p>
              Existing &amp; expired locks will continue receiving platform fees if expired locks
              are not withdrawn. New and existing RDNT holders have the option to stake in{' '}
              <a href="/#/staking">RDNT/ETH Liquidity Provider Staking</a>.
            </p>
            <p>
              These changes do not impact the money markets, existing lockers, or{' '}
              <a href="/#/staking">RDNT/ETH Liquidity Provider Staking</a>.
            </p>
          </div>
        </>
      )}
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .ManageRadiant__content-lock h3:after {
          background: url(${iconLock}) no-repeat;
        }
      `}</style>
    </div>
  );
}

export default ContentItemLock;
