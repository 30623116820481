import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ManageRadiant__content-item {
    background: #fff;
    height: 100%;
    border-radius: $borderRadius;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    padding: 20px 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .ManageRadiant__content-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: $large;
      color: #131313;
      padding-left: 40px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: -4px;
        width: 30px;
        height: 30px;
      }
    }

    span {
      background: #7159ff;
      color: #ffffff;
      padding: 6px 15px 5px;
      border-radius: 14.5px;
      font-size: $large;
    }
  }

  .ManageRadiant__content-description {
    margin-top: 15px;

    p {
      color: #000000;
      font-size: $medium;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .ManageRadiant__content-form {
  }

  .ManageRadiant__form-legend {
    display: flex;
    justify-content: space-between;
  }

  .ManageRadiant__input-label {
    font-size: $medium;
    color: #131313;
    font-weight: 600;
  }

  .ManageRadiant__value {
    height: 30px;
    text-align: right;
  }

  .ManageRadiant__value-rnd {
    color: #131313;
    font-size: $medium;
  }

  .ManageRadiant__value-usd {
    color: #000000;
    font-size: $extraSmall;
  }

  .ManageRadiant__form-controls {
    display: flex;
    justify-content: space-between;

    .ConnectButton__inner {
      width: 100px !important;
      min-height: 32px !important;
    }
  }

  .ManageRadiant__form-input {
  }

  .ManageRadiant__form-button {
  }
`;

export default staticStyles;
