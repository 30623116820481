import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Dashboard_Alert {
    color: #ffffff;
    background-color: #008c79;
    padding: 16px 20px;
    box-shadow: 0 1px 3px 0 #00000016;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  .DashboardWrapper {
    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      @include respond-to(sm) {
        position: relative;
        left: -10px;
        width: calc(100% + 20px);
      }
    }

    .ScreenWrapper__mobile-bottomBorder {
      display: none !important;
      @include respond-to(md) {
        display: block !important;
      }
    }
  }
`;

export default staticStyles;
