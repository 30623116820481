import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { providers } from 'ethers';

import { ChefIncentivesService } from 'libs/aave-protocol-js/ChefIncentivesContract/ChefIncentivesContract';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import { useStaticPoolDataContext } from 'libs/pool-data-provider';
import { useRdntBalanceContext } from 'libs/wallet-balance-provider/RdntBalanceProvider';
import { getProvider } from 'helpers/config/markets-and-network-config';
import { sendEthTransaction } from 'helpers/send-ethereum-tx';
import { useRdntethVestable } from 'libs/aave-protocol-js/hooks/use-rdnteth-vestable';

const useVestHandler = () => {
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const { chainId, currentMarketData } = useProtocolDataContext();
  const { userId } = useStaticPoolDataContext();
  const { vestable, fetchVestable } = useRdntBalanceContext();
  const { fetchData: fetchAvailableForVesting } = useRdntethVestable();

  return useCallback(
    async (event) => {
      if (!userId || !chainId) {
        return;
      }

      event.stopPropagation();
      event.preventDefault();

      const chefIncentivesService = new ChefIncentivesService(
        getProvider(chainId),
        currentMarketData.addresses.chefIncentivesController
      );

      const txGetter = await chefIncentivesService._claim(
        userId,
        currentMarketData.addresses.allTokens
      );

      return sendEthTransaction(
        txGetter,
        provider,
        () => {
          console.log('state setter');
        },
        null,
        {
          onConfirmation: () => {
            console.log('vested');
            fetchVestable();
            fetchAvailableForVesting();
          },
        }
      );
    },
    [userId, chainId, vestable.join(), fetchVestable]
  );
};

export default useVestHandler;
