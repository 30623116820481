import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Table__ClaimableRewards .TableHeader {
    padding: 0;
  }

  .feesUsd .Value__value {
    color: #000000;
    font-weight: 500;
    font-size: 14px !important;
  }
  .max120 {
    max-width: 120px;
  }

  .spin-content {
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  .table-header {
    border: none !important;
    margin-bottom: 0 !important;
  }

  .Table__ClaimableRewards .Table__body {
    width: 100%;
  }

  .Table__ClaimableRewards .TableValueCol__value {
    align-items: flex-start;
    @include respond-to(sm) {
      width: 100%;
    }
  }

  .Table__ClaimableRewards .TableItem .TableItem__token .TokenIcon__name {
    margin-top: 0;
  }

  .Table__ClaimableRewards .TableHeader__item {
    align-items: baseline !important;
  }

  .Table__ClaimableRewards .TableHeader__title {
    font-weight: 500 !important;
  }

  .Table__ClaimableRewards .SubValue {
    margin-top: 0 !important;
  }

  .Table__ClaimableRewards .Value__value {
    margin-top: 0;
  }

  .Table__ClaimableRewards .TableCol {
    align-items: baseline !important;
  }

  .Table__ClaimableRewards .Table__lastRowWithActions .TableButtonCol__button {
    width: 100%;
    font-size: 20px;
  }

  .Table__ClaimableRewards .TableItem {
    margin-bottom: 15px;
  }

  .Table__ClaimableRewards .TableCol:first-of-type {
    width: 35% !important;
    flex: inherit;
  }

  .Table__ClaimableRewards .TableItem__textOverride {
    border-top: 1px solid #80808069;
    padding-top: 10px;

    .boldy {
      display: none !important;
    }
    .SubValue {
      font-size: 19px;
      color: #242424;
      font-weight: bold;
    }
    .TokenIcon__name b {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .Table__ClaimableRewards .TableCol:last-of-type {
    width: 30% !important;
    flex: inherit;
    align-items: baseline;

    .Value__line {
      display: none;
      &.Value__subValue--line {
        display: block;
      }
    }
  }

  .Table__ClaimableRewards .TableItem:last-of-type {
    .TableItem__assetColor,
    .TableCol {
      opacity: 0;
      pointer-events: none;
    }

    .TableButtonsWrapper {
      .TableItem__assetColor,
      .TableCol {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .Table__lastRowWithActions {
    margin-top: 10px;
  }
`;

export default staticStyles;
