import { useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { providers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { PERMISSION } from '@aave/contract-helpers';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';

import PermissionWarning from 'ui-config/branding/PermissionWarning';
import depositConfirmationMessages from 'modules/deposit/screens/DepositConfirmation/messages';
import { useDynamicPoolDataContext, useStaticPoolDataContext } from 'libs/pool-data-provider';
import { useLinearVestData } from 'libs/aave-protocol-js/hooks/use-linear-vest-data';
import { GeistTokenContract } from 'libs/aave-protocol-js/GeistToken/GeistTokenContract';
import { TokenVestingContract } from 'libs/aave-protocol-js/TokenVesting/TokenVestingContract';
import { useApiDataProviderContext } from 'libs/api-data-provider/ApiDataProvider';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import { MultiFeeDistributionService } from 'libs/aave-protocol-js/MulteFeeDistribution/MultiFeeDistributionContract';
import { useRdntBalanceContext } from 'libs/wallet-balance-provider/RdntBalanceProvider';
import { useRdntPrices } from 'libs/aave-protocol-js/hooks/use-rdnt-prices';
import useVestHandler from 'modules/dashboard/components/RdntTableItem/useVestHandler';
import Value from 'components/basic/Value';
import ButtonTabs from 'components/basic/ButtonTabs';
import NoDataPanel from 'components/NoDataPanel';
import GradientLine from 'components/basic/GradientLine';
import ScreenWrapper from 'components/wrappers/ScreenWrapper';
import DefaultButton from 'components/basic/DefaultButton';
import { CompactNumber } from 'components/basic/CompactNumber';
import ContentWrapperWithTopLine from 'components/wrappers/ContentWrapperWithTopLine';
import { Table } from '../../components/Table';
import { TopStats } from '../../components/TopStats';
import ContentItemLock from '../../components/ContentItemLock';
import ContentItemAllLock from '../../components/ContentItemAllLock';
import { ContentItemHelp } from '../../components/ContentItemHelp';
import { ClaimableRewardsTable } from '../../components/ClaimableRewardsTable';
import ContentItem from '../../components/ContentItem';
import { sendEthTransaction } from 'helpers/send-ethereum-tx';
import { getProvider } from 'helpers/config/markets-and-network-config';
import messages from './messages';
import staticStyles from './style';
import QuickLockButton from 'components/basic/QuickLockButton';
import LoadingSpinner from 'components/LoadingSpinner';

const claimableRewardRerender = Math.random();
const priceInMarketReferenceCurrency = 0;

const VEST_TABS = ['Ready to Vest', 'Current Vesting', 'Vested'];

export function ManageRadiantMain() {
  const intl = useIntl();
  const { library: provider } = useWeb3React<providers.Web3Provider>();

  const { chainId, currentMarketData } = useProtocolDataContext();
  const { userId } = useStaticPoolDataContext();
  const { user } = useDynamicPoolDataContext();

  if (!user) {
    return (
      <NoDataPanel
        title={intl.formatMessage(depositConfirmationMessages.connectWallet)}
        description={intl.formatMessage(depositConfirmationMessages.connectWalletDescription)}
        withConnectButton={true}
      />
    );
  }

  const { lockingApr, platformStats, tokenPrices, tokenStats } = useApiDataProviderContext();
  const { linearVestData } = useLinearVestData();
  const { prices } = useRdntPrices();
  const vestHandler = useVestHandler();
  const { availableForVesting } = useRdntBalanceContext();

  const [loading, setLoading] = useState(false);
  const [tokenInfo, setTokenInfo] = useState<{
    walletBalance: BigNumber;
    currencySymbol: string;
    totalSupply: BigNumber;
  }>({
    walletBalance: valueToBigNumber(0),
    currencySymbol: 'RDNT',
    totalSupply: valueToBigNumber(0),
  });
  const [locked, setLocked] = useState<BigNumber>(valueToBigNumber(0));
  const [unlockable, setUnlockable] = useState<BigNumber>(valueToBigNumber(0));
  const [penalty, setPenalty] = useState<BigNumber>(valueToBigNumber(0));
  const [staked, setStaked] = useState<BigNumber>(valueToBigNumber(0));
  const [total, setTotal] = useState<BigNumber>(valueToBigNumber(0));
  const [earned, setEarned] = useState<BigNumber>(valueToBigNumber(0));
  const [lockedTable, setLockedTable] = useState<{ amount: string; expiryDate: Date }[]>([]);
  const [earnedTable, setEarnedTable] = useState<{ amount: string; expiryDate: Date }[]>([]);
  const [userShares, setUserShares] = useState<{ locking: number; staking: number }>({
    locking: 0,
    staking: 0,
  });
  const [userRevenue, setUserRevenue] =
    useState<{ total: number; platform: number; penalty: number }>();
  const [statsRerender, setStatsRerender] = useState<Number>(0);
  const [vestTab, setVestTab] = useState<string>(VEST_TABS[0]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const multiFeeDistributionService = new MultiFeeDistributionService(
          getProvider(chainId),
          currentMarketData.addresses.rdntToken,
          currentMarketData.addresses.multiFeeDistribution
        );
        const geistTokenContract = new GeistTokenContract(
          getProvider(chainId),
          currentMarketData.addresses.rdntToken
        );

        const [lockedTable, earnedTable, feeBalances, rdntInfo] = await Promise.all([
          multiFeeDistributionService.getLockedBalances(user.id),
          multiFeeDistributionService.getEarnedBalances(user.id),
          multiFeeDistributionService.getBalances(user.id),
          geistTokenContract.getInfo(user.id),
        ]);

        setLockedTable(lockedTable);
        setEarnedTable(earnedTable);
        const [
          lockedBal,
          unlockableBal, //stakedBal,
          ,
          earnedBal,
          withdrawableBal,
          penaltyBal,
          totalBal,
        ] = feeBalances;
        setTotal(totalBal);
        setLocked(lockedBal);
        setStaked(withdrawableBal.minus(penaltyBal));
        setUnlockable(unlockableBal);
        setPenalty(penaltyBal);
        setEarned(earnedBal);
        setTokenInfo(rdntInfo);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    })();
  }, [statsRerender]);

  useEffect(() => {
    (async () => {
      const stakingShare =
        (Number(staked) + Number(earned) + Number(locked)) /
        (Number(tokenStats.totalStaked) + Number(tokenStats.supplyLocked));
      const lockingShare = Number(locked) / Number(tokenStats.supplyLocked);

      setUserShares({
        locking: lockingShare * 100,
        staking: stakingShare * 100,
      });
    })();
  }, [statsRerender, tokenStats, locked, total, earned, staked]);

  useEffect(() => {
    (() => {
      // const globalPenFeesPerSecUsd = platformStats.penaltyFeesPerSecondUSD || 0;
      // const userDailyPenaltyFees =
      //   globalPenFeesPerSecUsd * ((userShares?.locking || 0) / 100) * 84600;

      const dailyPenFees = (platformStats.penaltyFeesPerSecondUSD || 0) * 86400;
      const userDailyPenFees = dailyPenFees * ((userShares?.locking || 0) / 100);

      const userExposure = locked.plus(earned).plus(staked);
      const totalPlatformFeeTokens = (tokenStats.supplyLocked || 0) + (tokenStats.totalStaked || 0);

      const userPlatformShare = Number(userExposure) / totalPlatformFeeTokens;
      const userPlatFeesPerSec = (platformStats.platformFeesPerSecondUSD || 0) * userPlatformShare;
      const userDailyPlatFees = userPlatFeesPerSec * 86400;

      const userTotalDailyRevenue = userDailyPlatFees + userDailyPenFees;

      const userRevPerDay = {
        total: userTotalDailyRevenue,
        platform: userDailyPlatFees,
        penalty: userDailyPenFees,
      };

      setUserRevenue(userRevPerDay);
    })();
  }, [platformStats, tokenStats, userShares, locked, earned, staked]);

  const unlockHandler = useCallback(async () => {
    const msg = `WARNING: This action will reduce your daily revenue.`;
    if (window.confirm(msg) === true) {
      const multiFeeDistributionService = new MultiFeeDistributionService(
        getProvider(chainId),
        currentMarketData.addresses.rdntToken,
        currentMarketData.addresses.multiFeeDistribution
      );

      const actionTx = await multiFeeDistributionService.withdrawExpiredLocks(user.id);
      return sendEthTransaction(actionTx, provider, () => {}, null, {
        onConfirmation: () => {
          setStatsRerender(Math.random());
        },
      });
    }
  }, [user, chainId, currentMarketData, provider, setStatsRerender]);

  const exitVestHandler = useCallback(async () => {
    const msg = `WARNING: You will take a ${Math.floor(Number(penalty))} RDNT penalty.`;
    if (window.confirm(msg) === true) {
      const multiFeeDistributionService = new MultiFeeDistributionService(
        getProvider(chainId),
        currentMarketData.addresses.rdntToken,
        currentMarketData.addresses.multiFeeDistribution
      );

      const actionTx = await multiFeeDistributionService.exit(user.id);
      return sendEthTransaction(actionTx, provider, () => {}, null, {
        onConfirmation: () => {
          setStatsRerender(Math.random());
        },
      });
    }
  }, [user, chainId, currentMarketData, provider, setStatsRerender]);

  const withdrawVestHandler = useCallback(async () => {
    const multiFeeDistributionService = new MultiFeeDistributionService(
      getProvider(chainId),
      currentMarketData.addresses.rdntToken,
      currentMarketData.addresses.multiFeeDistribution
    );

    const actionTx = await multiFeeDistributionService.withdraw(user.id, staked.toString());
    const approveTxData = {
      txType: actionTx[0].txType,
      unsignedData: actionTx[0].tx,
      gas: actionTx[0].gas,
    };

    return sendEthTransaction(approveTxData.unsignedData, provider, () => {}, null, {
      onConfirmation: () => {
        setStatsRerender(Math.random());
      },
    });
  }, [user, chainId, currentMarketData, provider, staked, setStatsRerender]);

  const vestButtonHandler = useCallback(
    async (event) => {
      await vestHandler(event);
      setStatsRerender(Math.random());
    },
    [vestHandler]
  );

  const handleLinearVest = useCallback(async () => {
    if (!userId) {
      alert('Check wallet connection');
      return;
    }

    try {
      const masterChefContract = new TokenVestingContract(
        getProvider(chainId),
        currentMarketData.addresses.vesting
      );
      const res = await masterChefContract._claim(userId);
      return sendEthTransaction(res, provider, () => {}, null, {
        onConfirmation: () => {
          alert('Vest claimed');
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [userId, chainId, currentMarketData, provider]);

  return (
    <>
      <PermissionWarning requiredPermission={PERMISSION.BORROWER}>
        <ScreenWrapper
          pageTitle={intl.formatMessage(messages.title)}
          isTitleOnDesktop={true}
          withMobileGrayBg={true}
        >
          <div className="ManageRadiant">
            <div className="ManageRadiant__left-container">
              <ContentWrapperWithTopLine title="" className="ManageRadiantMain__top-revenue">
                <LoadingSpinner loading={loading} />
                <div className="ManageRadiantMain__revenue-item">
                  <TopStats
                    title={intl.formatMessage(messages.lockedStakedGeist)}
                    infoText={intl.formatMessage(messages.helpIconLockedStaked)}
                    value={total.toNumber()}
                    dollarPrefix={false}
                    tooltipPlace="bottom"
                    subValue={prices.tokenPrice ? Number(total) * prices.tokenPrice : undefined}
                  >
                    <div className="data-grid">
                      <p>
                        Locked:
                        <span>
                          <CompactNumber value={locked.toString()} />
                        </span>
                      </p>
                      <p>
                        Vesting:
                        <span>
                          <CompactNumber value={earned.toString()} />
                        </span>
                      </p>
                    </div>
                  </TopStats>
                </div>

                <GradientLine size={1} direction="vertical" />

                <div className="ManageRadiantMain__revenue-item">
                  <TopStats
                    title={`Daily Platform Fees (Global)`}
                    infoText={
                      <>
                        {intl.formatMessage(messages.helpIconDailyFeesTitle)}
                        <br />
                        <br />
                        <small>{intl.formatMessage(messages.helpIconDailyFees1)}</small>
                        <br />
                        <br />
                        <small>{intl.formatMessage(messages.helpIconDailyFees2)}</small>
                      </>
                    }
                    value={(platformStats.totalRevenuePerSecondUSD || 0) * 86400}
                    dollarPrefix={true}
                  />

                  <div className="data-grid">
                    <p>
                      Your Penalty Share:
                      <span>
                        {intl.formatNumber(Number(userShares?.locking), {
                          maximumFractionDigits: 2,
                        })}
                        %
                      </span>
                    </p>
                    <p>
                      Your Platform Share:
                      <span>
                        {intl.formatNumber(Number(userShares?.staking), {
                          maximumFractionDigits: 2,
                        })}
                        %
                      </span>
                    </p>
                  </div>
                </div>

                <GradientLine size={1} direction="vertical" />

                <div className="ManageRadiantMain__revenue-item green">
                  <TopStats
                    title={`Your Daily Revenue`}
                    infoText={intl.formatMessage(messages.helpIconDailyRevenue)}
                    value={userRevenue?.total || 0}
                    dollarPrefix={true}
                    showFullNum={true}
                  >
                    <div className="data-grid">
                      <p>
                        Penalty Fees:
                        <span>
                          $
                          <CompactNumber
                            value={userRevenue?.penalty || 0}
                            minimumFractionDigits={2}
                          />
                        </span>
                      </p>
                      <p>
                        Platform Fees:
                        <span>
                          $
                          <CompactNumber
                            value={userRevenue?.platform || 0}
                            minimumFractionDigits={2}
                          />
                        </span>
                      </p>
                      <p className="italic small">(paid over the next 7 days)</p>
                    </div>
                  </TopStats>
                </div>
              </ContentWrapperWithTopLine>

              <div className="ManageRadiant__card-container">
                <ContentItem
                  className="ManageRadiant__content-lock"
                  title="Lock RDNT"
                  apr={`${
                    lockingApr >= 10.0
                      ? Math.round(lockingApr * 100).toLocaleString()
                      : (Math.round(lockingApr * 10000) / 100).toLocaleString()
                  }%`}
                >
                  {Number(tokenInfo.walletBalance) > 0.1 && false ? (
                    <ContentItemAllLock
                      apr={lockingApr}
                      maxAmount={tokenInfo.walletBalance.toString()}
                      walletBalance={tokenInfo.walletBalance}
                      onMainTxConfirmed={() => {
                        setStatsRerender(Math.random());
                      }}
                    />
                  ) : null}

                  <ContentItemLock
                    apr={lockingApr}
                    maxAmount={tokenInfo.walletBalance.toString()}
                    currencySymbol={tokenInfo.currencySymbol}
                    depositBalance={locked}
                    walletBalance={tokenInfo.walletBalance}
                    priceInMarketReferenceCurrency={priceInMarketReferenceCurrency.toString(10)}
                    onMainTxConfirmed={() => {
                      setStatsRerender(Math.random());
                    }}
                  />

                  <Table
                    title="Locks"
                    action="Locked"
                    value={locked.toString()}
                    table={lockedTable}
                  />

                  {!!Number(unlockable) && (
                    <>
                      <GradientLine size={2} />
                      <div className="Table">
                        <div className="Table__body">
                          <div className="Table__row">
                            <div className="Table__column">
                              <h4>Redeemable Locks</h4>
                              <p>
                                Keep your RDNT locked to continue receiving platform + penalty fees.
                              </p>
                            </div>
                          </div>
                          <div className="Table__row">
                            <div className="Table__column">
                              <Value
                                symbol={'RDNT'}
                                value={unlockable.toString()}
                                tokenIcon={true}
                                leftSided={true}
                              />
                            </div>
                            <div className="Table__column">
                              <DefaultButton
                                title="Unlock"
                                color="dark"
                                transparent={true}
                                onClick={unlockHandler}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </ContentItem>

                <div className="ManageRadiant__content-item">
                  <h2>Vesting</h2>

                  <p className="vestIntro">
                    {vestTab === VEST_TABS[0] &&
                      'RDNT earned from lending, borrowing and staking must vest for 28 days.'}
                    {vestTab === VEST_TABS[1] &&
                      'Earn platform revenue while your RDNT is vesting.'}
                    {vestTab === VEST_TABS[2] &&
                      'RDNT that has completed the 28 day vesting period.'}
                  </p>

                  <ButtonTabs tabs={VEST_TABS} selectedTab={vestTab} setSelectedTab={setVestTab} />

                  {vestTab === VEST_TABS[0] && (
                    <div className="container">
                      <div className="child">
                        <div className="Vesting_Button">
                          <TopStats
                            value={Number(availableForVesting)}
                            dollarPrefix={false}
                            subValue={
                              prices.tokenPrice
                                ? Number(availableForVesting) * prices.tokenPrice
                                : undefined
                            }
                          />
                        </div>
                      </div>
                      <div className="child">
                        <QuickLockButton
                          mode="rewards"
                          chainId={chainId}
                          currentMarketData={currentMarketData}
                          availableForVesting={availableForVesting}
                          user={user}
                          onMainTxConfirmed={() => {
                            setStatsRerender(Math.random());
                          }}
                        />

                        <DefaultButton
                          onClick={vestButtonHandler}
                          transparent={true}
                          disabled={!availableForVesting}
                          color="dark"
                          title={intl.formatMessage(messages.startVesting)}
                          size="big"
                        />
                      </div>
                    </div>
                  )}

                  {vestTab === VEST_TABS[1] && (
                    <>
                      <div className="container">
                        <div className="child">
                          <div className="Vesting_Button">
                            <TopStats
                              value={Number(earned)}
                              dollarPrefix={false}
                              subValue={
                                prices.tokenPrice ? Number(earned) * prices.tokenPrice : undefined
                              }
                            />
                          </div>
                        </div>
                        <div className="child">
                          <QuickLockButton
                            mode="vesting"
                            chainId={chainId}
                            currentMarketData={currentMarketData}
                            availableForVesting={earned}
                            user={user}
                            onMainTxConfirmed={() => {
                              setStatsRerender(Math.random());
                            }}
                          />

                          <DefaultButton
                            onClick={exitVestHandler}
                            transparent={true}
                            color="dark"
                            title="Exit Early"
                            size="big"
                          />
                          <p className="description">
                            Penalty:{' '}
                            {intl.formatNumber(Number(penalty), {
                              maximumFractionDigits: 2,
                            })}{' '}
                            RDNT
                          </p>
                        </div>
                      </div>
                      <Table title="Vests" value={earned.toString()} table={earnedTable} />
                    </>
                  )}

                  {vestTab === VEST_TABS[2] && (
                    <div className="container">
                      <div className="child">
                        <div className="Vesting_Button">
                          <TopStats
                            value={Number(staked)}
                            dollarPrefix={false}
                            subValue={
                              prices.tokenPrice ? Number(staked) * prices.tokenPrice : undefined
                            }
                          />
                        </div>
                      </div>
                      <div className="child">
                        <DefaultButton
                          onClick={withdrawVestHandler}
                          transparent={true}
                          color="dark"
                          title="Withdraw"
                          size="big"
                        />
                      </div>
                    </div>
                  )}
                </div>

                {!!linearVestData && (
                  <div className="ManageRadiant__content-item">
                    <h2>Linear Vests</h2>

                    <GradientLine size={2} />

                    <h3 style={{ margin: '15px 0' }}>
                      <CompactNumber value={linearVestData} /> RDNT
                    </h3>

                    <div className="Table__column">
                      <DefaultButton
                        title="Claim"
                        color="dark"
                        size="big"
                        transparent={true}
                        onClick={handleLinearVest}
                      />
                    </div>
                  </div>
                )}

                <ContentItemHelp />
              </div>
            </div>
            <div className="ManageRadiant__right-container">
              <div className="ManageRadiant__content-card">
                <ClaimableRewardsTable
                  rerender={claimableRewardRerender}
                  tokenPrices={tokenPrices}
                />
              </div>
            </div>
          </div>
        </ScreenWrapper>
      </PermissionWarning>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}

export default ManageRadiantMain;
