import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .home-header {
    padding: 35px 0;
    display: flex;
    justify-content: space-between;
    @include respond-to(md) {
      flex-direction: column;
      align-items: center;
    }

    &--left {
      flex: 1;
    }

    &--right {
      max-width: 435px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      @include respond-to(md) {
        gap: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        max-width: 100%;
      }

      &__buy {
        align-self: center;
      }
    }

    @include respond-to(sm) {
      padding: 24px 0;
    }

    .logo-row {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 40px;

      @include respond-to(sm) {
        gap: 0;
        flex-direction: column;
      }

      img {
        width: 240px;
      }

      h3 {
        font-size: 30px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 4px;
      }
    }

    .button-row {
      display: flex;
      margin: 20px 0;
      gap: 10px;

      @include respond-to(sm) {
        flex-direction: column;
      }

      .secondary {
        background: none;
        color: #7159ff;
        border: 2px solid #7159ff;
        font-size: 18px;
        width: 180px;
        height: 50px;
        border-radius: 15px;
        box-shadow: $boxShadow;
      }

      .primary {
        font-size: 18px;
        width: 180px;
        height: 50px;
        border-radius: 15px;
        box-shadow: $boxShadow;

        &.wide {
          width: 260px;
        }
      }
    }

    .description {
      font-size: 64px;
      font-weight: 700;
      margin: 10px 0;
      line-height: 1;
      color: #7159ff;
      text-transform: uppercase;
      width: fit-content;
      background: -webkit-linear-gradient(107.5deg, #47f7cf -3.19%, #7159ff 101.2%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @include respond-to(sm) {
        font-size: 48px;
      }
    }

    .subheader {
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }

  .green {
    h2 {
      color: #7159ff;
      text-shadow: 0 0 5px #f5f6fa;
    }
  }

  .Markets__size {
    @include respond-to(sm) {
      flex-direction: column;
    }
  }

  .Markets {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 30px !important;
    @include respond-to(sm) {
      margin-top: 0 !important;
    }

    &__top-content {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding: 30px;
      border-radius: 5px;
      border: solid 1px rgba(255, 255, 255, 0.5);
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;

      @include respond-to(md) {
        gap: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      &__buy {
        align-self: center;
      }
    }

    &__top-content-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      max-width: 200px;
      width: 100%;

      button {
        width: 130px;
      }
    }

    &__table-wrapper {
      position: relative;
      margin-top: 30px;
      padding: 0px 20px;
      border-radius: 5px;
      border: solid 1px rgba(255, 255, 255, 0.5);
      background: #ffffff 0% 0% no-repeat padding-box;
    }

    &__mobile--cards {
      display: none;
      @include respond-to(sm) {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
`;

export default staticStyles;
