import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useThemeContext } from '@aave/aave-ui-kit';

import DashboardTable from 'modules/dashboard/components/DashboardTable';
import TableHeader from 'modules/dashboard/components/DashboardTable/TableHeader';
import DashboardMobileCardsWrapper from 'modules/dashboard/components/DashboardMobileCardsWrapper';
import DepositItem from './DepositItem';
import DepositMobileCard from './DepositMobileCard';
import messages from './messages';
import { DepositTableItem } from './types';

interface DepositDashboardTableProps {
  listData: DepositTableItem[];
}

export default function DepositDashboardTable({ listData }: DepositDashboardTableProps) {
  const intl = useIntl();
  const { lg, md, sm } = useThemeContext();

  const head = [
    intl.formatMessage(messages.yourDeposits),
    intl.formatMessage(messages.secondTableColumnTitle),
    intl.formatMessage(messages.apyRowTitle),
    intl.formatMessage(messages.collateral),
  ];

  const colWidth = useMemo(
    () => (md ? ['100%', 380, 110, '100%'] : lg ? [245, 380, 110, '100%'] : [95, 125, 125, 125]),
    [md, lg]
  );

  return !sm ? (
    <>
      <TableHeader head={head} colWidth={colWidth} isDeposit={true} />

      <DashboardTable>
        {listData.map((item, index) => (
          <DepositItem
            {...item}
            index={index}
            key={index}
            data-cy={`dashboardDespositListItem${item.reserve.symbol}`}
          />
        ))}
      </DashboardTable>
    </>
  ) : (
    <DashboardMobileCardsWrapper>
      {listData.map((item, index) => (
        <DepositMobileCard {...item} key={index} />
      ))}
    </DashboardMobileCardsWrapper>
  );
}
