import { ChainId } from '@aave/contract-helpers';

import { BaseNetworkConfig } from 'helpers/config/types';
import avalancheBridgeLogo from 'ui-config/branding/images/avalancheLogo.svg';
import arbitrum_rinkeby from 'ui-config/addresses/arbitrum_rinkeby.json';
import arbitrum_one from 'ui-config/addresses/arbitrum_one.json';
import local from 'ui-config/addresses/local.json';

export const networkConfigs: Record<string, BaseNetworkConfig> = {
  [ChainId.arbitrum_rinkeby]: {
    name: 'Arbitrum Rinkeby',
    publicJsonRPCUrl: [
      'https://black-little-flower.arbitrum-testnet.quiknode.pro/92a45299ed079d95585108b54cb06d65f4e2e9d8/',
    ],
    publicJsonRPCWSUrl: 'wss://rinkeby.arbitrum.io/rpc',
    addresses: arbitrum_rinkeby,
    protocolDataUrl: '',
    baseUniswapAdapter: '0x0',
    baseAsset: 'ARETH',
    baseAssetWrappedAddress: '0x217F76f6332aAC6854CAf224B98eD95412940151',
    rewardTokenSymbol: 'RDNT',
    rewardTokenAddress: '',
    rewardTokenDecimals: 0,
    incentivePrecision: 0,
    explorerLink: 'https://testnet.arbiscan.io/',
    rpcOnly: true,
    usdMarket: true,
    isTestnet: true,
    isLocalTestnet: false,
    bridge: {
      brandColor: '232, 65, 66',
      name: 'Arbitrum Bridge',
      url: 'https://bridge.arbitrum.io',
      logo: avalancheBridgeLogo, // TO-DO: Update
    },
  },
  [ChainId.arbitrum_one]: {
    name: 'Arbitrum',
    publicJsonRPCUrl: ['https://rpc.radiant.capital/70ff72eec58b50f824282a0c28f3434d585c9410/'],
    publicJsonRPCWSUrl: 'wss://rinkeby.arbitrum.io/rpc',
    addresses: arbitrum_one,
    protocolDataUrl: '',
    baseUniswapAdapter: '0x0',
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    rewardTokenSymbol: 'RDNT',
    rewardTokenAddress: '',
    rewardTokenDecimals: 0,
    incentivePrecision: 0,
    explorerLink: 'https://arbiscan.io/',
    rpcOnly: true,
    usdMarket: true,
    isTestnet: false,
    isLocalTestnet: false,
    bridge: {
      brandColor: '232, 65, 66',
      name: 'Arbitrum Bridge',
      url: 'https://bridge.arbitrum.io',
      logo: avalancheBridgeLogo, // TO-DO: Update
    },
  },
  [ChainId.local]: {
    name: 'Local - Test',
    publicJsonRPCUrl: ['http://127.0.0.1:8545/'],
    publicJsonRPCWSUrl: 'wss://rinkeby.arbitrum.io/rpc',
    addresses: local,
    protocolDataUrl: '',
    baseUniswapAdapter: '0x0',
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0x217F76f6332aAC6854CAf224B98eD95412940151',
    rewardTokenSymbol: 'RDNT',
    rewardTokenAddress: '',
    rewardTokenDecimals: 0,
    incentivePrecision: 0,
    explorerLink: 'https://testnet.arbiscan.io/',
    rpcOnly: true,
    usdMarket: true,
    isTestnet: true,
    isLocalTestnet: true,
    bridge: {
      brandColor: '232, 65, 66',
      name: 'Arbitrum Bridge',
      url: 'https://bridge.arbitrum.io',
      logo: avalancheBridgeLogo, // TO-DO: Update
    },
  },
} as const;
