import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ContentItemHelp__buttons {
    display: flex;
    justify-content: space-between;
  }
`;

export default staticStyles;
