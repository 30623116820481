import Link from 'components/basic/Link';
import DefaultButton from 'components/basic/DefaultButton';
import ContentItem from '../ContentItem';
import iconCoins from '../../images/icon-coins.svg';
import staticStyles from './style';

export function ContentItemHelp() {
  return (
    <>
      <ContentItem
        className="ManageRadiant__content-help"
        title="Help"
        description={
          <p>
            Need help?
            <br />
            View the GitBook, or reach out to us on Telegram or Discord.
          </p>
        }
      >
        <div className="ContentItemHelp__buttons">
          <Link
            to={'http://docs.radiant.capital'}
            absolute
            inNewWindow={true}
            className="ContentItemHelp__button"
          >
            <DefaultButton title="Docs" size="medium" type="submit" color="purple" transparent />
          </Link>
          <Link
            to={'https://t.me/radiantcapitalofficial'}
            absolute
            inNewWindow={true}
            className="ContentItemHelp__button"
          >
            <DefaultButton
              title="Telegram"
              size="medium"
              type="submit"
              color="purple"
              transparent
            />
          </Link>
          <Link
            to={'https://discord.gg/radiantcapital'}
            absolute
            inNewWindow={true}
            className="ContentItemHelp__button"
          >
            <DefaultButton title="Discord" size="medium" type="submit" color="purple" transparent />
          </Link>
        </div>
      </ContentItem>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .ManageRadiant__content-help h3:after {
          background: url(${iconCoins}) no-repeat;
        }
      `}</style>
    </>
  );
}
