import { Switch, Route, Redirect } from 'react-router-dom';

import { StakeDataProvider } from 'libs/pool-data-provider/hooks/use-stake-data-context';
import ManagerRadiantMain from './screens/ManageRadiantMain';

export default function ManageRadiant() {
  return (
    <StakeDataProvider>
      <Switch>
        <Route exact={true} path="/manage-radiant" component={ManagerRadiantMain} />

        <Redirect to="/borrow" />
      </Switch>
    </StakeDataProvider>
  );
}
