import { useIntl } from 'react-intl';
import { valueToBigNumber, InterestRate } from '@aave/protocol-js';
import { ComputedUserReserve } from '@aave/math-utils';

import { loanActionLinkComposer } from 'helpers/loan-action-link-composer';
import { ComputedReserveData } from 'libs/pool-data-provider';
import Link from 'components/basic/Link';
import DefaultButton from 'components/basic/DefaultButton';
import defaultMessages from 'defaultMessages';
import staticStyles from './style';

interface BorrowActionProps {
  symbol: string;
  poolReserve: ComputedReserveData;
  userReserve?: ComputedUserReserve;
  type: 'stable' | 'variable';
  availableBorrows: number;
}

export default function BorrowAction({
  symbol,
  poolReserve,
  userReserve,
  type,
  availableBorrows,
}: BorrowActionProps) {
  const intl = useIntl();

  const borrows = valueToBigNumber(userReserve?.totalBorrows || '0').toNumber();

  const repayLink = loanActionLinkComposer(
    'repay',
    poolReserve.id,
    InterestRate.Variable,
    poolReserve.underlyingAsset
  );

  const borrowLink = loanActionLinkComposer(
    'borrow',
    poolReserve.id,
    type === 'stable' ? InterestRate.Stable : InterestRate.Variable,
    poolReserve.underlyingAsset
  );

  return (
    <div className="BorrowAction">
      <div className="BorrowAction__column">
        <Link
          to={borrowLink}
          className="ButtonLink"
          disabled={!availableBorrows || !poolReserve.borrowingEnabled || poolReserve.isFrozen}
        >
          <DefaultButton
            className="BorrowAction__button"
            title={intl.formatMessage(defaultMessages.borrow)}
            color="purple"
            disabled={!availableBorrows || !poolReserve.borrowingEnabled || poolReserve.isFrozen}
          />
        </Link>

        <Link to={repayLink} className="ButtonLink" disabled={!borrows}>
          <span className="BorrowAction__button BorrowAction__buttonNoBorder">
            {intl.formatMessage(defaultMessages.repay)}
          </span>
        </Link>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
