import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useWeb3React } from '@web3-react/core';
import { providers } from 'ethers';
import { useThemeContext, SpinLoader } from '@aave/aave-ui-kit';

import { getProvider } from 'helpers/config/markets-and-network-config';
import { sendEthTransaction } from 'helpers/send-ethereum-tx';
import { useDynamicPoolDataContext } from 'libs/pool-data-provider';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import { useRdntPrices } from 'libs/aave-protocol-js/hooks/use-rdnt-prices';
import { MultiFeeDistributionService } from 'libs/aave-protocol-js/MulteFeeDistribution/MultiFeeDistributionContract';
import { useRdntBalanceContext } from 'libs/wallet-balance-provider/RdntBalanceProvider';
import TableItem from 'modules/dashboard/components/DashboardTable/TableItem';
import TableValueCol from 'modules/dashboard/components/DashboardTable/TableValueCol';
import Value from 'components/basic/Value';
import GradientLine from 'components/basic/GradientLine';
import DefaultButton from 'components/basic/DefaultButton';
import staticStyles from './style';

interface ClaimableRewardsTableProps {
  rerender?: number;
  tokenPrices: [
    {
      symbol?: string;
      rToken?: string;
      price?: number;
    }
  ];
}

export function ClaimableRewardsTable({
  rerender = 0.01,
  tokenPrices = [{}],
}: ClaimableRewardsTableProps) {
  const intl = useIntl();
  const { chainId, currentMarketData } = useProtocolDataContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const { user } = useDynamicPoolDataContext();
  const { prices } = useRdntPrices();
  const { currentTheme } = useThemeContext();
  const { refetch } = useRdntBalanceContext();

  const [statsRerender, setStatsRerender] = useState<Number>(0);
  const [claimableRewards, setClaimableRewards] = useState<
    { tokSymbol?: string; token: string; amount: number; usdVal: number }[]
  >([]);
  const [totalFees, setTotalFees] = useState<any>(0);

  if (!user) {
    return null;
  }

  let tokPrices: any = {};
  tokenPrices.map((tok: any) => {
    tokPrices[tok.symbol] = tok.price;
    return null;
  });

  tokPrices['RDNT'] = prices.tokenPrice;

  const queryClaimableRewards = useCallback(async () => {
    const multiFeeDistribution = new MultiFeeDistributionService(
      getProvider(chainId),
      currentMarketData.addresses.rdntToken,
      currentMarketData.addresses.multiFeeDistribution
    );
    const _rewards = await multiFeeDistribution.claimableRewards(user.id);
    const rewards: { tokSymbol?: string; token: string; amount: number; usdVal: number }[] = [];

    if (tokenPrices.length && tokPrices['RDNT']) {
      _rewards.forEach(({ token, amount }, i) => {
        const isRdntToken = token === currentMarketData.addresses.rdntToken;
        const reserveToken = tokenPrices.find(({ rToken }) => rToken === token);

        let tokSymbol = isRdntToken ? 'RDNT' : reserveToken ? reserveToken.symbol : '';
        if (tokSymbol) {
          let usdVal = amount * tokPrices[tokSymbol];
          rewards.push({
            tokSymbol,
            token,
            amount,
            usdVal,
          });
        }
      });

      if (rewards.length > 0) {
        let order = ['RDNT', 'DAI', 'USDC', 'USDT', 'WETH', 'WBTC'];
        let orderedRewards: any = [];
        let fees = 0;
        for (const tok of order) {
          let t = rewards.filter((rew) => rew.tokSymbol === tok);
          if (t.length) {
            orderedRewards.push(t[0]);
            fees += t[0].usdVal;
          }
        }
        setClaimableRewards(orderedRewards);
        setTotalFees(fees);
      }
    }
    refetch();
  }, [tokenPrices, prices, refetch]);

  useEffect(() => {
    queryClaimableRewards();
  }, [statsRerender, tokenPrices, prices]);

  return (
    <>
      <div className="Table Table__ClaimableRewards">
        <h3>Your Platform Revenue</h3>

        {claimableRewards.length === 0 ? (
          <div className="spin-content">
            <SpinLoader color={currentTheme.purple.hex} />
          </div>
        ) : (
          <>
            <TableItem
              key={'header'}
              maxWidthOverride={160}
              tokenSymbol={''}
              textOverride={'Token'}
              inline
              className="table-header"
            >
              <TableValueCol value={0} addedClasses="boldy max120" />
              <p>Claimable Fees</p>
            </TableItem>

            <GradientLine size={2} />

            <div className="Table__body">
              {claimableRewards.map((reward, i) => (
                <TableItem
                  key={reward.tokSymbol}
                  maxWidthOverride={160}
                  tokenSymbol={reward.tokSymbol || ''}
                  inline
                >
                  <TableValueCol value={reward.amount} addedClasses="boldy max120" />

                  <Value
                    value={reward.usdVal}
                    minimumValueDecimals={2}
                    maximumValueDecimals={2}
                    showDollarSign={true}
                    className="feesUsd"
                  />
                </TableItem>
              ))}

              <TableItem key={'total'} tokenSymbol={''} textOverride={'Total:'} inline>
                <TableValueCol value={0} addedClasses="boldy" />
                <p className="boldness">
                  $
                  {intl.formatNumber(totalFees, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </p>
              </TableItem>

              <div className="Table__lastRowWithActions">
                <DefaultButton
                  className="TableButtonCol__button"
                  color="purple"
                  title={'Claim All'}
                  disabled={!claimableRewards.some(({ amount }) => amount > 0)}
                  onClick={async (event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    const multiFeeDistributionService = new MultiFeeDistributionService(
                      getProvider(chainId),
                      currentMarketData.addresses.rdntToken,
                      currentMarketData.addresses.multiFeeDistribution
                    );
                    const txGetter = await multiFeeDistributionService.getReward(
                      user.id,
                      claimableRewards.map(({ token }) => token)
                    );

                    return sendEthTransaction(txGetter, provider, () => {}, null, {
                      onConfirmation: () => {
                        setStatsRerender(Math.random());
                      },
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
