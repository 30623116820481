import Value from 'components/basic/Value';
import TableCol from '../TableCol';
import staticStyles from './style';

interface TableValueColProps {
  value: number;
  subValue?: number;
  tooltipId?: string;
  addedClasses?: string;
  addDollar?: boolean;
}

export default function TableValueCol({
  value,
  subValue,
  tooltipId,
  addedClasses,
}: TableValueColProps) {
  let classes = `TableValueCol__value ${addedClasses}`;
  return (
    <TableCol className={`${addedClasses}`}>
      <Value
        value={value}
        subValue={subValue}
        subSymbol="USD"
        maximumValueDecimals={2}
        minimumValueDecimals={2}
        minimumSubValueDecimals={2}
        maximumSubValueDecimals={2}
        tooltipId={tooltipId}
        className={classes}
      />

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </TableCol>
  );
}
