import { providers } from 'ethers';
import BaseService from '@aave/contract-helpers/dist/esm/commons/BaseService.js';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  tEthereumAddress,
  transactionType,
} from '@aave/contract-helpers/dist/esm/commons/types';
import {
  ERC20Service,
  IERC20ServiceInterface,
} from '@aave/contract-helpers/dist/esm/erc20-contract';

import { TokenVesting__factory } from './TokenVesting__factory';
import { TokenVesting } from './TokenVesting';

export class TokenVestingContract extends BaseService<TokenVesting> {
  public readonly contractAddress: tEthereumAddress;

  readonly erc20Service: IERC20ServiceInterface;

  constructor(provider: providers.Provider, tokenVestingAddress: string) {
    super(provider, TokenVesting__factory);

    this.contractAddress = tokenVestingAddress;
    this.erc20Service = new ERC20Service(provider);
  }

  public async claimable(user: tEthereumAddress) {
    const tokenVestingContract: TokenVesting = this.getContractInstance(this.contractAddress);
    const res = await tokenVestingContract.callStatic.claimable(user);
    let claimable = Number(BigInt(res._hex)) / Number(10 ** 18);
    return claimable;
  }

  public async claim(user: tEthereumAddress): Promise<EthereumTransactionTypeExtended[]> {
    const txs: EthereumTransactionTypeExtended[] = [];

    const tokenVestingContract: TokenVesting = this.getContractInstance(this.contractAddress);
    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () => tokenVestingContract.populateTransaction.claim(user),
      from: user,
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.STAKE_ACTION,
      gas: this.generateTxPriceEstimation(txs, txCallback),
    });

    return txs;
  }

  public _claim(user: tEthereumAddress): () => Promise<transactionType> {
    const tokenVestingContract: TokenVesting = this.getContractInstance(this.contractAddress);

    return this.generateTxCallback({
      rawTxMethod: () => tokenVestingContract.populateTransaction.claim(user),
      from: user,
    });
  }
}
