import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useThemeContext } from '@aave/aave-ui-kit';

import ScreenWrapper from '../ScreenWrapper';
import ContentWrapper from '../ContentWrapper';
import TopInfoPanel from './TopInfoPanel';
import CurrencyOverview from './CurrencyOverview';
import { ValidationWrapperComponentProps } from '../../RouteParamsValidationWrapper';
import { GraphLegendDot } from '../../graphs/GraphLegend';
import { getAssetInfo, TokenIcon } from 'helpers/config/assets-config';
import { InterestRateSeries } from '../../graphs/types';
import ContentWrapperWithTopLine from '../ContentWrapperWithTopLine';
import staticStyles from './style';
import messages from './messages';

interface CurrencyScreenWrapperProps
  extends Pick<
    ValidationWrapperComponentProps,
    'userReserve' | 'poolReserve' | 'user' | 'currencySymbol'
  > {
  title: string;
  isCollapseLocalStorageName: string;
  walletBalance?: string;
  type: 'deposit' | 'borrow';
  showGraphCondition: boolean;
  dots?: GraphLegendDot[];
  series: InterestRateSeries[];
  goBack?: () => void;
  children: ReactNode;
  subRightForm?: ReactNode;
  withBackButton?: boolean;
}

export default function CurrencyScreenWrapper({
  title,
  currencySymbol,
  poolReserve,
  user,
  userReserve,
  walletBalance,
  isCollapseLocalStorageName,
  type,
  showGraphCondition,
  dots,
  series,
  goBack,
  children,
  subRightForm,
  withBackButton = true,
}: CurrencyScreenWrapperProps) {
  const intl = useIntl();
  const { sm } = useThemeContext();
  const isCollapse = false;
  const asset = getAssetInfo(currencySymbol);

  return (
    <ScreenWrapper
      pageTitle={intl.formatMessage(type === 'deposit' ? messages.deposit : messages.borrow)}
      className="CurrencyScreenWrapper"
      isTitleOnDesktop
      titleComponent={
        <div className="CurrencyScreenWrapper__token-title">
          <TokenIcon tokenSymbol={currencySymbol} height={20} width={20} />
          <p>{intl.formatMessage(messages.caption, { symbol: asset && asset.name })}</p>
        </div>
      }
    >
      {sm && (
        <ContentWrapper
          className="CurrencyScreenWrapper__content"
          withBackButton={true}
          goBack={goBack}
        >
          {children}
        </ContentWrapper>
      )}

      <div className="container1">
        <div className="container2 o2">
          {subRightForm && subRightForm}
          <ContentWrapperWithTopLine title={''}>
            <CurrencyOverview
              title={title}
              poolReserve={poolReserve}
              currencySymbol={currencySymbol}
              type={type}
              showGraphCondition={showGraphCondition}
              dots={dots}
              series={series}
              isCollapse={isCollapse}
            >
              <TopInfoPanel
                poolReserve={poolReserve}
                currencySymbol={currencySymbol}
                walletBalance={walletBalance}
                userReserve={userReserve}
                user={user}
                type={type}
              />
            </CurrencyOverview>
          </ContentWrapperWithTopLine>
        </div>
        <div className="container2 o1">
          <div className="CurrencyScreenWrapper__mobileInner">
            <TopInfoPanel
              poolReserve={poolReserve}
              currencySymbol={currencySymbol}
              walletBalance={walletBalance}
              userReserve={userReserve}
              user={user}
              type={type}
            />
          </div>
          {!sm && (
            <ContentWrapper
              className="CurrencyScreenWrapper__content"
              withBackButton={withBackButton}
              goBack={goBack}
            >
              {children}
            </ContentWrapper>
          )}
        </div>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </ScreenWrapper>
  );
}
