import { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { valueToBigNumber } from '@aave/protocol-js';
import { useThemeContext } from '@aave/aave-ui-kit';

import { useDynamicPoolDataContext } from 'libs/pool-data-provider';
import { useRdntPrices } from 'libs/aave-protocol-js/hooks/use-rdnt-prices';
import { useRdntBalanceContext } from 'libs/wallet-balance-provider/RdntBalanceProvider';
import { useApiDataProviderContext } from 'libs/api-data-provider/ApiDataProvider';
import { TopStats } from 'modules/manage-radiant/components/TopStats';
import useVestHandler from 'modules/dashboard/components/RdntTableItem/useVestHandler';
import { getAssetInfo, getAssetColor } from 'helpers/config/assets-config';
import DefaultButton from 'components/basic/DefaultButton';
import CircleCompositionBar, {
  CircleCompositionBarItem,
} from 'components/compositionBars/CircleCompositionBar';
import LTVInfoModal from 'components/LTVInfoModal';
import ContentWrapperWithTopLine from 'components/wrappers/ContentWrapperWithTopLine';
import GradientLine from 'components/basic/GradientLine';
import MaxLTVHelpModal from 'components/HelpModal/MaxLTVHelpModal';
import TextWithTooltip from 'components/TextWithTooltip';
import messages from './messages';
import staticStyles from './style';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import QuickLockButton from 'components/basic/QuickLockButton';
import LoadingSpinner from 'components/LoadingSpinner';
import { isEmpty } from 'helpers/utility';

export default function DepositBorrowTopPanel() {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { user, reserves } = useDynamicPoolDataContext();
  const { availableForVesting } = useRdntBalanceContext();
  const { lockingApr } = useApiDataProviderContext();
  const vestHandler = useVestHandler();
  const { prices } = useRdntPrices();
  const { chainId, currentMarketData } = useProtocolDataContext();

  const [isCollapse] = useState(localStorage.getItem('borrowDepositTopPanelIsCollapse') === 'true');
  const [isLTVModalVisible, setLTVModalVisible] = useState(false);

  const maxBorrowAmount = valueToBigNumber(user?.totalBorrowsMarketReferenceCurrency || '0').plus(
    user?.availableBorrowsMarketReferenceCurrency || '0'
  );

  const collateralUsagePercent = maxBorrowAmount.eq(0)
    ? '1'
    : valueToBigNumber(user?.totalBorrowsMarketReferenceCurrency || '0')
        .div(maxBorrowAmount)
        .toFixed();

  const loanToValue =
    user?.totalCollateralMarketReferenceCurrency === '0'
      ? '0'
      : valueToBigNumber(user?.totalBorrowsMarketReferenceCurrency || '0')
          .dividedBy(user?.totalCollateralMarketReferenceCurrency || '1')
          .toFixed();

  const depositCompositionData: CircleCompositionBarItem[] = [];
  const borrowCompositionData: CircleCompositionBarItem[] = [];
  user?.userReservesData.forEach((userReserve) => {
    const poolReserve = reserves.find((res) => res.symbol === userReserve.reserve.symbol);

    if (!poolReserve) {
      throw new Error('data is inconsistent pool reserve is not available');
    }
    if (userReserve.underlyingBalance !== '0' || userReserve.totalBorrows !== '0') {
      if (userReserve.underlyingBalance !== '0') {
        depositCompositionData.push({
          label: `${getAssetInfo(userReserve.reserve.symbol).formattedName}  ${intl.formatNumber(
            valueToBigNumber(userReserve.underlyingBalanceMarketReferenceCurrency)
              .dividedBy(user?.totalLiquidityMarketReferenceCurrency)
              .multipliedBy(100)
              .toNumber(),
            { maximumFractionDigits: 2 }
          )}%`,
          value: Number(userReserve.underlyingBalanceMarketReferenceCurrency),
          color: getAssetColor(userReserve.reserve.symbol),
        });
      }
      if (userReserve.totalBorrows !== '0') {
        borrowCompositionData.push({
          label: `${getAssetInfo(userReserve.reserve.symbol).formattedName}  ${intl.formatNumber(
            valueToBigNumber(userReserve.totalBorrowsMarketReferenceCurrency)
              .dividedBy(maxBorrowAmount)
              .multipliedBy(100)
              .toNumber(),
            { maximumFractionDigits: 2 }
          )}%`,
          value: Number(userReserve.totalBorrowsMarketReferenceCurrency),
          color: getAssetColor(userReserve.reserve.symbol),
        });
      }
    }
  });

  const availableBorrowPower = maxBorrowAmount.eq(0)
    ? '0'
    : valueToBigNumber(user?.availableBorrowsMarketReferenceCurrency || '0').div(maxBorrowAmount);

  borrowCompositionData.push({
    value: valueToBigNumber(user?.availableBorrowsMarketReferenceCurrency || 0).toNumber(),
    label: `${intl.formatMessage(messages.borrowingPowerAvailable)}: ${intl.formatNumber(
      Number(availableBorrowPower) * 100,
      {
        maximumFractionDigits: 2,
      }
    )}%`,
    color: currentTheme.darkBlue.hex,
  });

  return (
    <div className="DepositBorrowTopPanel">
      <div className="ManageRadiant__content-item">
        <div className="ManageRadiantMain__top">
          <ContentWrapperWithTopLine title="" className="ManageRadiantMain__top-revenue">
            <LoadingSpinner loading={isEmpty(user) || isEmpty(reserves) || isEmpty(prices)} />
            <div className="ManageRadiantMain__revenue-item">
              <TopStats
                title={`Deposits`}
                infoText={
                  'Your aggregated balance shows the approximate value in USD of all the assets you have deposited. It fluctuates based on the evolution of prices.' +
                  ' Please note that if your deposits consist of stablecoins, the approximate balance in USD could be slightly higher or lower than the stablecoin balance due to fluctuations in the stablecoin/USD peg.'
                }
                value={
                  user && Number(user.totalLiquidityUSD) !== 0
                    ? Number(user.totalLiquidityUSD)
                    : 0.0
                }
                dollarPrefix={true}
                showFullNum={true}
              />
              {!isCollapse && !!depositCompositionData.length && (
                <div className="DepositBorrowTopPanel__topPanel-bars">
                  <CircleCompositionBar
                    title={intl.formatMessage(messages.depositComposition)}
                    totalValue={Number(user?.totalLiquidityMarketReferenceCurrency || 0)}
                    data={depositCompositionData}
                  />
                </div>
              )}
            </div>

            <GradientLine size={1} direction="vertical" />

            <div className="ManageRadiantMain__revenue-item Borrows">
              <TopStats
                title={`Borrows`}
                infoText={'The total of all your borrowed assets.'}
                value={
                  user && Number(user.totalBorrowsUSD) !== 0 ? Number(user.totalBorrowsUSD) : 0.0
                }
                dollarPrefix={true}
                showFullNum={true}
              />
              {!!borrowCompositionData.length && (
                <div className="DepositBorrowTopPanel__topPanel-bars">
                  <CircleCompositionBar
                    title={intl.formatMessage(messages.borrowComposition)}
                    totalValue={Number(maxBorrowAmount || 0)}
                    data={borrowCompositionData}
                  />
                </div>
              )}
            </div>

            <GradientLine size={1} direction="vertical" />

            <div className="ManageRadiantMain__revenue-item health-factor">
              <TopStats
                title={`Health Factor`}
                infoText={
                  'The health factor represents the safety of your loan derived from the proportion of collateral versus amount borrowed. Keep it above 1 to avoid liquidation.'
                }
                value={Number((user?.healthFactor || 0) > 0 ? user?.healthFactor : Infinity)}
                dollarPrefix={false}
                showFullNum={true}
                isHealthFactor={true}
              >
                <div className="data-grid">
                  <div className="col">
                    <p>Borrowing Power Used</p>
                    <p>
                      <MaxLTVHelpModal
                        text={intl.formatMessage(messages.currentLTV)}
                        onWhiteBackground={true}
                      />
                    </p>
                  </div>
                  <div className="col numberic">
                    <p>
                      {intl.formatNumber(Number(collateralUsagePercent) * 100, {
                        maximumFractionDigits: 2,
                      })}{' '}
                      %
                    </p>
                    <p>
                      {intl.formatNumber(Number(loanToValue) * 100, { maximumFractionDigits: 2 })} %
                    </p>
                  </div>
                </div>
              </TopStats>
              {loanToValue !== '0' && (
                <div className="Details_Button">
                  <DefaultButton
                    color="dark"
                    title={intl.formatMessage(messages.details)}
                    transparent={true}
                    className={classNames('DepositBorrowTopPanel__button', {
                      DepositBorrowTopPanel__buttonCollapse: isCollapse,
                    })}
                    size="big"
                    onClick={() => setLTVModalVisible(true)}
                  />
                </div>
              )}
            </div>

            <GradientLine size={1} direction="vertical" />

            <div className="ManageRadiantMain__revenue-item">
              <TopStats
                title={`RDNT Rewards`}
                infoText={intl.formatMessage(messages.helpIconRewards)}
                value={Number(availableForVesting)}
                dollarPrefix={false}
                showFullNum={true}
                subValue={
                  prices.tokenPrice ? Number(availableForVesting) * prices.tokenPrice : undefined
                }
              />

              <div className="Vesting_Button">
                <DefaultButton
                  onClick={vestHandler}
                  transparent={true}
                  // disabled={!availableForVesting}
                  color="dark"
                  title={intl.formatMessage(messages.startVesting)}
                  size="big"
                  className="button"
                />
              </div>
            </div>
          </ContentWrapperWithTopLine>
        </div>
      </div>

      {loanToValue !== '0' && (
        <LTVInfoModal visible={isLTVModalVisible} setVisible={setLTVModalVisible} />
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
