import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import staticStyles from './style';
import DefaultButton from '../DefaultButton';
import { QuickLockContract } from 'libs/aave-protocol-js/QuickLock/QuickLockContract';
import { getProvider } from 'helpers/config/markets-and-network-config';
import PoolTxConfirmationView from 'components/PoolTxConfirmationView';
import closeIcon from 'images/close.png';

interface QuickLockProps {
  mode: string;
  chainId: any;
  currentMarketData: any;
  availableForVesting: any;
  user: any;
  title?: string;
  className?: string;
  onMainTxConfirmed?: () => void;
}

export default function QuickLockButton({
  mode,
  chainId,
  currentMarketData,
  availableForVesting,
  user,
  title,
  className,
  onMainTxConfirmed = () => {},
}: QuickLockProps) {
  const history = useHistory();

  const [isQlModalVisible, setQlModalVisible] = useState(false);

  const handleGetTransactions = async () => {
    const userId = user?.id || '';

    const qlContract = new QuickLockContract(
      getProvider(chainId),
      currentMarketData.addresses.quicklock
    );
    return await qlContract.quicklock(mode, userId, chainId, currentMarketData);
  };

  const handleMainTxExecuted = () => console.log(`maintxn complete`);
  const blockingError = '';

  const onAfterSuccessClick = useCallback(() => {
    setQlModalVisible(false);
    history.push('/manage-radiant');
    onMainTxConfirmed();
  }, [setQlModalVisible, history]);

  return (
    <>
      <DefaultButton
        onClick={() => setQlModalVisible(true)}
        disabled={true}
        color="purple"
        title={title || `1-Click Vest + Lock`}
        size="big"
        className={className}
      />

      {isQlModalVisible && (
        <div className={`ql-modal`}>
          <div className={`ql-modal-body`}>
            <img
              src={closeIcon}
              alt="close-icon"
              className="ql-close-icon"
              onClick={() => setQlModalVisible(false)}
            />
            <h3>Quick Lock</h3>

            <p className="desc">This will claim, exit, and lock all of your vesting RDNT.</p>
            <p className="desc small">
              1-Click Locking incurs a 50% early exit penalty on any RDNT that has not been vested
              to full maturity. You can always manually lock RDNT from the Manage Radiant page.
            </p>
            <PoolTxConfirmationView
              mainTxName={`Quick Lock`}
              boxTitle={`Quick Lock`}
              boxDescription={`Lock all claimable & vesting RDNT`}
              getTransactionsData={handleGetTransactions}
              onMainTxExecuted={handleMainTxExecuted}
              blockingError={blockingError}
              successButtonTitle={'View Locks'}
              onAfterSuccessClick={onAfterSuccessClick}
              goToAfterSuccess=""
            />
          </div>
        </div>
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
