import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { valueToBigNumber } from '@aave/protocol-js';
import { useThemeContext } from '@aave/aave-ui-kit';

import { useDynamicPoolDataContext, useStaticPoolDataContext } from 'libs/pool-data-provider';
import { useApiDataProviderContext } from 'libs/api-data-provider/ApiDataProvider';
import { useRdntPrices } from 'libs/aave-protocol-js/hooks/use-rdnt-prices';
import useRdntLendingPoolRewards from 'libs/aave-protocol-js/hooks/use-rdnt-lending-pool-rewards';
import Link from 'components/basic/Link';
import BuyRDNTModal from 'components/BuyRDNTModal';
import DefaultButton from 'components/basic/DefaultButton';
import ScreenWrapper from 'components/wrappers/ScreenWrapper';
import MarketTable from '../../components/MarketTable';
import MarketTableItem from '../../components/MarketTableItem';
import TotalMarketsSize from '../../components/TotalMarketsSize';
import TotalSupply from '../../components/TotalSupply';
import MarketMobileCard from '../../components/MarketMobileCard';
import TotalPlatformFees from '../../components/TotalPlatformFees';
import TokenLockPanel from '../../components/TokenLockPanel';
import messages from './messages';
import staticStyles from './style';
import LoadingSpinner from 'components/LoadingSpinner';
import { isEmpty } from 'helpers/utility';

export default function Markets() {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { reserves } = useDynamicPoolDataContext();
  const { prices } = useRdntPrices();
  const { dailyFees, pool2Info, lockingApr } = useApiDataProviderContext();
  const { getRewardApr } = useRdntLendingPoolRewards();

  const [sortName, setSortName] = useState('');
  const [sortDesc, setSortDesc] = useState(false);
  const [openBuyModal, setOpenBuyModal] = useState(false);
  const [totalLockedInUsd, setTotalLockedInUsd] = useState(valueToBigNumber('0'));
  const [marketData, setMarketData] = useState<any[]>([]);

  const getMarketData = useCallback(() => {
    let totalLockedInUsd = valueToBigNumber('0');

    const marketData = reserves
      .filter((res) => res.isActive && !res.isFrozen)
      .map((reserve) => {
        totalLockedInUsd = totalLockedInUsd.plus(
          valueToBigNumber(reserve.totalLiquidity)
            .multipliedBy(reserve.priceInMarketReferenceCurrency)
            .multipliedBy(marketRefPriceInUsd)
        );

        const totalLiquidity = Number(reserve.totalLiquidity);
        const totalLiquidityInUSD = valueToBigNumber(reserve.totalLiquidity)
          .multipliedBy(reserve.priceInMarketReferenceCurrency)
          .multipliedBy(marketRefPriceInUsd)
          .toNumber();

        const totalBorrows = Number(reserve.totalDebt);
        const totalBorrowsInUSD = valueToBigNumber(reserve.totalDebt)
          .multipliedBy(reserve.priceInMarketReferenceCurrency)
          .multipliedBy(marketRefPriceInUsd)
          .toNumber();

        const { rdntRewardsDepositApr = 0, rdntRewardsBorrowApr = 0 } = getRewardApr(reserve);

        return {
          id: reserve.id,
          underlyingAsset: reserve.underlyingAsset,
          currencySymbol: reserve.symbol,
          totalLiquidity,
          totalLiquidityInUSD,
          totalBorrows: reserve.borrowingEnabled ? totalBorrows : -1,
          totalBorrowsInUSD: reserve.borrowingEnabled ? totalBorrowsInUSD : -1,
          depositAPY: reserve.borrowingEnabled ? Number(reserve.supplyAPY) : -1,
          avg30DaysLiquidityRate: Number(reserve.avg30DaysLiquidityRate),
          variableBorrowRate: reserve.borrowingEnabled ? Number(reserve.variableBorrowAPY) : -1,
          avg30DaysVariableRate: Number(reserve.avg30DaysVariableBorrowRate),
          borrowingEnabled: reserve.borrowingEnabled,
          isFreezed: reserve.isFrozen,
          rdntRewardsDepositApr,
          rdntRewardsBorrowApr,
        };
      });

    setMarketData(marketData);
    setTotalLockedInUsd(totalLockedInUsd);
  }, [reserves, marketRefPriceInUsd, setMarketData, setTotalLockedInUsd]);

  useEffect(() => {
    getMarketData();
  }, [getMarketData]);

  const sortedData = useMemo(() => {
    const sortedDataValue = marketData;

    if (sortDesc) {
      if (sortName === 'currencySymbol') {
        sortedDataValue.sort((a, b) =>
          b.currencySymbol.toUpperCase() < a.currencySymbol.toUpperCase() ? -1 : 0
        );
      } else {
        // @ts-ignore
        sortedDataValue.sort((a, b) => a[sortName] - b[sortName]);
      }
    } else {
      if (sortName === 'currencySymbol') {
        sortedDataValue.sort((a, b) =>
          a.currencySymbol.toUpperCase() < b.currencySymbol.toUpperCase() ? -1 : 0
        );
      } else {
        // @ts-ignore
        sortedDataValue.sort((a, b) => b[sortName] - a[sortName]);
      }
    }
    return sortedDataValue;
  }, [sortDesc, sortName, marketData]);

  return (
    <ScreenWrapper
      pageTitle={intl.formatMessage(messages.pageTitle)}
      className="Markets"
      withMobileGrayBg={true}
    >
      {openBuyModal && <BuyRDNTModal setOpenModal={setOpenBuyModal} />}

      <div className="home-header">
        <div className="home-header--left">
          <p className="description">{'Earn interest & borrow assets cross-chain in 3 clicks'}</p>
          <p className="subheader">Welcome to the future of Omnichain lending</p>

          <div className="button-row">
            <Link to={'http://docs.radiant.capital'} absolute inNewWindow>
              <DefaultButton title={'Learn More'} color="purple" className="secondary" size="big" />
            </Link>
            <DefaultButton
              title={'Buy RDNT'}
              color="purple"
              className="primary wide"
              size="big"
              onClick={() => setOpenBuyModal(true)}
            />
          </div>
        </div>
        <div className="home-header--right">
          <TokenLockPanel />
        </div>
      </div>

      <div className="Markets__top-content">
        <LoadingSpinner loading={marketData.length === 0 || isEmpty(prices)} />

        <TotalMarketsSize value={totalLockedInUsd.toNumber()} />

        <TotalPlatformFees value={dailyFees.totalPlatformFees || 0} />

        <div className="Markets__top-content-item">
          <TotalSupply
            titleKey="token"
            descriptionKey="tokenDescription"
            value={prices?.tokenPrice || 0}
          />
          <DefaultButton
            title={'Buy'}
            color="purple"
            transparent={true}
            onClick={() => setOpenBuyModal(true)}
          />
        </div>

        <div className="Markets__top-content-item">
          <TotalSupply
            titleKey="lockApr"
            descriptionKey="lockAprDescription"
            value={lockingApr * 100}
            isPercent
          />
          <Link to={'/manage-radiant'}>
            <DefaultButton title={'Lock'} color="purple" transparent={true} />
          </Link>
        </div>

        <div className="Markets__top-content-item">
          <TotalSupply
            isPercent
            titleKey="pool2Apr"
            descriptionKey="pool2AprDescription"
            value={(pool2Info.apr || 0) * 100}
          />
          <Link to={'/staking'}>
            <DefaultButton title={'Stake'} color="purple" transparent={true} />
          </Link>
        </div>
      </div>

      <div className="Markets__table-wrapper">
        <LoadingSpinner loading={sortedData.length === 0} />
        <MarketTable
          sortName={sortName}
          setSortName={setSortName}
          sortDesc={sortDesc}
          setSortDesc={setSortDesc}
        >
          {sortedData.map((item, index) => (
            <MarketTableItem {...item} isPriceInUSD={true} key={index} />
          ))}
        </MarketTable>
      </div>

      <div className="Markets__mobile--cards">
        <LoadingSpinner loading={sortedData.length === 0} />
        {sortedData.map((item, index) => (
          <MarketMobileCard {...item} key={index} />
        ))}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .Markets {
          &__top-content {
            color: ${currentTheme.white.hex};
          }
        }
      `}</style>
    </ScreenWrapper>
  );
}
