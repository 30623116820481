import { useThemeContext } from '@aave/aave-ui-kit';

export default function VersionBanner() {
  const { isCurrentThemeDark } = useThemeContext();

  return (
    <div className="VersionBanner">
      <p className="VersionBanner__title">
        You are using a deprecated version of the Radiant app. This version is no longer supported.
        Please
        <a href="https://app.radiant.capital/#/migration" target="_blank" rel="noreferrer">
          click here
        </a>
        {''} to migrate to v2.
      </p>

      <style jsx={true} global={true}>
        {`
          @import 'src/_mixins/vars';
          @import 'src/_mixins/screen-size';
          .VersionBanner {
            position: relative;
            z-index: 2;
            // display: flex;
            justify-content: center;
            padding: 10px;
            gap: 10px;
            background-color: ${isCurrentThemeDark ? '#DFE7F3' : '#0f172a'};
            margin: auto;
            width: 100%;

            &__title {
              align-items: center;
              text-align: center;
              color: ${isCurrentThemeDark ? '#000000' : '#fff'};
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.02em;
              @include respond-to(sm) {
                width: 90%;
                flex-direction: column;
              }
              a {
                padding-left: 5px;
                color: ${isCurrentThemeDark ? '#000000' : '#fff'};
                text-decoration: underline;
              }
            }
          }
        `}
      </style>
    </div>
  );
}
