import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ContentWrapperWithTopLine {
    &__withDropdown {
      .ContentWrapperWithTopLine__top-line {
        cursor: pointer;
      }
      .ContentWrapperWithTopLine__content {
        display: none;
      }
      .ContentWrapperWithTopLine__contentActive {
        display: block;
      }
    }

    &__top-line {
      padding: 0 0;
      font-size: $large;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      min-height: 0px; //49px;

      @include respond-to(sm) {
        padding: 10px;
        font-size: $regular;
        min-height: 39px;
      }
    }

    &__noTitle {
      display: none;
    }

    &__arrow-inner {
      display: flex;
      align-items: center;
      font-size: $medium;
      span {
        margin-right: 5px;
      }
    }

    &__content {
      border-radius: $borderRadius;
      border: solid 1px rgba(255, 255, 255, 0.5);
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.8) -6%,
        rgba(255, 255, 255, 0.9) 59%
      );
      flex: 1;
    }
  }
`;

export default staticStyles;
