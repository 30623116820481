import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { rgba, useThemeContext } from '@aave/aave-ui-kit';

import { LOGO } from 'ui-config';
import { useUserWalletDataContext } from 'libs/web3-data-provider';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import { useLanguageContext } from 'libs/language-provider';
import goToTop from 'helpers/goToTop';
import Link from 'components/basic/Link';
import MarketSwitcher from 'components/market/MarketSwitcher';
import MenuLink from '../MenuLink';
import MoreButton from '../MoreButton';
import DaoButton from '../DaoButton';
import AddressInfo from '../AddressInfo';
import RdntBalance from '../RdntBalance';
import MobileContent from '../MobileContent';
import staticStyles from './style';
import navigation from '../navigation';
import backIcon from 'images/mobileBackArrow.svg';
import BuyRDNTModal from 'components/BuyRDNTModal';

interface MenuProps {
  title: string;
}

export default function Menu({ title }: MenuProps) {
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { currentAccount } = useUserWalletDataContext();
  const { currentMarketData } = useProtocolDataContext();
  const { currentLangSlug } = useLanguageContext();

  const [openBuyModal, setOpenBuyModal] = useState(false);

  const isActive = (url: string) => {
    return `/${url.split('/')[1]}` === `/${location.pathname.split('/')[1]}`;
  };

  const topLineColor = rgba(`${currentTheme.white.rgb}, 0.1`);

  return (
    <header className="Menu">
      <div className="Menu__logo-inner">
        <Link className="Menu__logo-link" to="/markets" onClick={() => goToTop()}>
          <img src={LOGO} alt="Radiant" />
        </Link>
      </div>

      <div className="Menu__title-inner">
        {history.length > 2 && (
          <button className="Menu__back-button" onClick={history.goBack}>
            <img src={backIcon} alt="" />
          </button>
        )}

        <p>{title}</p>
      </div>

      <div className="Menu__right-inner">
        <nav className="Menu__navigation-inner">
          <ul>
            {navigation
              .filter((item) => currentLangSlug !== 'es' || !item.isSpanishMoreButton)
              .map((link, index) => (
                <li
                  className={classNames('Menu__link-inner', {
                    Menu__linkHidden:
                      (!currentAccount && link.hiddenWithoutWallet) ||
                      (link.isVisible && !link.isVisible(currentMarketData)),
                  })}
                  key={index}
                >
                  <MenuLink
                    to={link.isBuyRDNT ? '' : link.link}
                    title={intl.formatMessage(link.title)}
                    isActive={isActive(link.link)}
                    absolute={link.absolute}
                    onClick={() => link.isBuyRDNT && setOpenBuyModal(true)}
                  />
                </li>
              ))}
            <li className="Menu__link-inner">
              <DaoButton />
            </li>
            <li className="Menu__link-inner">
              <MoreButton setOpenBuyModal={setOpenBuyModal} />
            </li>
          </ul>
        </nav>

        <div className="Menu__buttons-inner">
          <MarketSwitcher />
          <AddressInfo />
          <RdntBalance />
        </div>

        <div className="Menu__burger-inner">
          <MobileContent
            isActive={isActive}
            currentAccount={currentAccount}
            setOpenBuyModal={setOpenBuyModal}
          />
        </div>
      </div>

      {openBuyModal && <BuyRDNTModal setOpenModal={setOpenBuyModal} />}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .Menu {
          background: ${currentTheme.headerBg.hex};
          &:after {
            background: ${topLineColor};
          }

          &__title-inner {
            p {
              color: ${currentTheme.white.hex};
            }
          }
        }
      `}</style>
    </header>
  );
}
