import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .container1 {
    display: flex;
    margin-top: 25px;

    @include respond-to(sm) {
      margin-top: 0;
    }

    .container2 {
      display: flex;
      gap: 20px;

      @include respond-to(sm) {
        flex-direction: row;
      }

      &.o1 {
        width: 65%;
        @include respond-to(sm) {
          display: none;
        }
      }
      &.o2 {
        display: flex;
        flex-direction: column;
        gap: 35px;

        order: 2;
        width: 35%;
        margin-left: 35px;

        @include respond-to(sm) {
          width: 100%;
          margin-left: 0px;
        }
      }
      .CurrencyOverview__content-left,
      .CurrencyOverview__content,
      .ContentWrapperWithTopLine,
      .CurrencyOverview__inner {
        flex-direction: column;
        width: 100%;
      }
      .ContentWrapper {
        margin-top: 0;
      }
      .ContentWrapperWithTopLine__top-line {
        display: none;
      }
      .CurrencyOverview__inner {
        margin-bottom: 15px;
      }
    }
  }
  .CurrencyScreenWrapper {
    @include respond-to(sm) {
      margin-top: 0 !important;
      padding-bottom: 0 !important;
      .ScreenWrapper__mobile-bottomBorder {
        display: none;
      }
    }

    &__token-title {
      display: flex;

      img {
        height: 35px;
        width: 35px;
        position: absolute;
      }

      p {
        padding-left: 45px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      margin: 30px 0 10px;
      padding: 60px 0;
      background: #fff;
      height: fit-content;

      @include respond-to(sm) {
        margin: 40px 0;
        padding: 0;
        background: transparent !important;
        box-shadow: none !important;
        display: block;
      }
    }

    &__mobileInner {
      display: none;
    }

    .NoDataPanel {
      @include respond-to(sm) {
        min-height: unset;
      }
    }

    .DepositAmount__poolLink--button {
      .Button__wrapper {
        flex-direction: row-reverse;
        img {
          margin-left: 10px;
          width: 14px;
          height: 14px;
          @include respond-to(xl) {
            width: 12px;
            height: 12px;
          }
          @include respond-to(sm) {
            width: 16px;
            height: 16px;
            margin-left: 15px;
          }
        }
      }
    }
  }

  @media (max-height: 750px) {
    .CurrencyScreenWrapper {
      .CurrencyScreenWrapper__content {
        display: block;
      }
    }
  }
`;

export default staticStyles;
