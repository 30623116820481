import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import queryString from 'query-string';

import { useRdntethTokenInfo } from 'libs/aave-protocol-js/hooks/use-rdnteth-token-info';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import BasicForm from 'components/forms/BasicForm';
import defaultMessages from 'defaultMessages';
import messages from './messages';

const selectedStake = 'RDNT';
const stakeDisclaimerHidden = true;

export default function StakeAmount() {
  const intl = useIntl();
  const history = useHistory();
  const { chainId, currentMarketData } = useProtocolDataContext();
  const { tokenInfo } = useRdntethTokenInfo();

  const handleSubmit = useCallback((amount: string) => {
    const query = queryString.stringify({ amount });
    history.push(
      `/staking/${selectedStake}/${!stakeDisclaimerHidden ? 'disclaimer' : 'confirmation'}?${query}`
    );
  }, []);

  const lpLinkHandler = useCallback(() => {
    window.open(
      `https://app.sushi.com/legacy/add/0x82aF49447D8a07e3bd95BD0d56f35241523fBab1/${
        currentMarketData?.addresses?.rdntToken || ''
      }/?chainId=${chainId}`,
      '_blank'
    );
  }, [currentMarketData, chainId]);

  return (
    <>
      <BasicForm
        title={intl.formatMessage(messages.caption)}
        description={intl.formatMessage(messages.description, {
          uniSwap: (
            <strong onClick={lpLinkHandler} className={'lp-link'}>
              {' '}
              {intl.formatMessage(messages.uniSwap)}{' '}
            </strong>
          ),
        })}
        amountFieldTitle={intl.formatMessage(messages.availableToStake)}
        maxAmount={tokenInfo.walletBalance ? tokenInfo.walletBalance.toString() : 0}
        currencySymbol={`RDNT/WETH`}
        onSubmit={handleSubmit}
        submitButtonTitle={intl.formatMessage(defaultMessages.stake)}
      />
      <style jsx={true} global={true}>
        {`
          .lp-link {
            cursor: pointer;
            text-decoration: underline;
          }
        `}
      </style>
    </>
  );
}
