import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .BorrowForm {
    max-width: 550px;
    margin: 0 auto;

    &__warning {
      max-width: 430px;
      margin: 0 auto;
      text-align: center;
      font-size: $medium;
      @include respond-to(xl) {
        max-width: 335px;
        font-size: $small;
      }
      @include respond-to(lg) {
        font-size: $extraSmall;
      }
      @include respond-to(md) {
        font-size: $small;
      }
      @include respond-to(sm) {
        font-size: $medium;
      }
    }

    &__select {
      margin-top: 20px;

      p {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }

    &__select-button {
      display: flex;
      align-items: center;
      font-size: 16px;
      padding: 10px;

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: contain;
        margin-right: 10px;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      @include respond-to(sm) {
        margin-bottom: 50px;
      }
    }

    .BorrowForm__select {
      p {
        font-size: 16px;
      }
    }

    .Caption {
      h2 {
        font-size: 28px !important;
      }
    }

    .Button__big {
      font-size: 20px;
      width: auto;
      padding: 10px 50px;
    }

    .BorrowForm__xchain-desc {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 20px;
      p {
        font-size: 12px;
      }
      a {
        font-size: 12px;
        color: #000000;
      }
    }
  }
`;

export default staticStyles;
