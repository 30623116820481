import React, { useContext, useState, useEffect } from 'react';

import { getProvider } from 'helpers/config/markets-and-network-config';
import { useStaticPoolDataContext } from 'libs/pool-data-provider';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import { TokenVestingContract } from '../TokenVesting/TokenVestingContract';

type LinearVestDataProviderContext = {
  linearVestData: number;
};

const Context = React.createContext<LinearVestDataProviderContext>(
  {} as LinearVestDataProviderContext
);

export const LinearVestDataProvider: React.FC = ({ children }) => {
  const { userId } = useStaticPoolDataContext();
  const { chainId, currentMarketData } = useProtocolDataContext();
  const [linearVestData, setLinearVestData] = useState<number>(0);

  useEffect(() => {
    if (!userId) {
      return;
    }

    const tick = async () => {
      try {
        const tokenVestingContract = new TokenVestingContract(
          getProvider(chainId),
          currentMarketData.addresses.vesting
        );

        const res = await tokenVestingContract.claimable(userId);
        setLinearVestData(res);
      } catch (e) {
        console.log(e);
      }
    };

    tick();
    setInterval(tick, 60 * 1000);
  }, [userId]);

  return (
    <Context.Provider
      value={{
        linearVestData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useLinearVestData = () => useContext(Context);
