import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import BigNumber from 'bignumber.js';
import queryString from 'query-string';

import { MasterChefContract } from 'libs/aave-protocol-js/MasterChef/MasterChefContract';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import { useRdntethStake } from 'libs/aave-protocol-js/hooks/use-rdnteth-stake';
import { useStaticPoolDataContext } from 'libs/pool-data-provider';
import Row from 'components/basic/Row';
import Value from 'components/basic/Value';
import StakeTxConfirmationView from '../../components/StakeTxConfirmationView';
import { getProvider } from 'helpers/config/markets-and-network-config';
import messages from './messages';

export default function UnstakeConfirmation() {
  const intl = useIntl();
  const location = useLocation();
  const { userId } = useStaticPoolDataContext();
  const { chainId, currentMarketData } = useProtocolDataContext();
  const { userStakedAmount } = useRdntethStake();

  const query = queryString.parse(location.search);
  let amount = new BigNumber(typeof query.amount === 'string' ? query.amount : 0);

  if (amount.eq(0) || !userId) {
    return null;
  }

  let blockingError = '';
  if (amount.gt(userStakedAmount)) {
    blockingError = intl.formatMessage(messages.blockingError);
  }

  const handleGetTransactions = async () => {
    const masterChefContract = new MasterChefContract(
      getProvider(chainId),
      currentMarketData.addresses.stakingToken,
      currentMarketData.addresses.masterChef
    );

    return masterChefContract.withdraw(userId, amount.toString());
  };

  return (
    <StakeTxConfirmationView
      caption={intl.formatMessage(messages.title)}
      description={intl.formatMessage(messages.description)}
      getTransactionsData={handleGetTransactions}
      boxTitle={intl.formatMessage(messages.unstakeAsset, { asset: 'RDNT/WETH'.toUpperCase() })}
      boxDescription={intl.formatMessage(messages.boxDescription)}
      mainTxName={intl.formatMessage(messages.unstake)}
      goToAfterSuccess="/staking"
      successButtonTitle={intl.formatMessage(messages.backToStaking)}
      blockingError={blockingError}
      buttonTitle={intl.formatMessage(messages.unstake)}
      onMainTxConfirmed={() => {
        const event = new Event('stakeTxnConfirmed');
        document.dispatchEvent(event);
      }}
    >
      <Row title={intl.formatMessage(messages.amount)}>
        <Value
          symbol={'RDNT/WETH'.toUpperCase()}
          value={amount.toString()}
          tokenIcon={true}
          tooltipId={'RDNT/WETH'.toUpperCase()}
        />
      </Row>
    </StakeTxConfirmationView>
  );
}
