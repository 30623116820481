import { MessageDescriptor } from 'react-intl';

import { moreMenuExtraItems, moreMenuItems, moreMenuMobileOnlyItems } from 'ui-config';
import { MarketDataType } from 'helpers/config/types';
import { isFeatureEnabled } from 'helpers/config/markets-and-network-config';
import messages from './messages';

export interface Navigation {
  link: string;
  title: MessageDescriptor;
  hiddenWithoutWallet?: boolean;
  absolute?: boolean;
  onClick?: () => void;
  isVisible?: (data: MarketDataType) => boolean | undefined;
  isBuyRDNT?: boolean;
  isSpanishMoreButton?: boolean;
}

const navigation: Navigation[] = [
  {
    link: '/markets',
    title: messages.markets,
  },
  {
    link: '/dashboard',
    title: messages.dashboard,
  },
  // {
  //   link: '/loop',
  //   title: messages.loop,
  // },
  {
    link: '/deposit',
    title: messages.deposit,
  },
  {
    link: '/borrow',
    title: messages.borrow,
  },
  {
    link: 'https://app.radiant.capital/#/migration',
    title: messages.migration,
    absolute: true,
  },
  {
    link: '/manage-radiant',
    title: messages.manageRadiant,
  },
  {
    link: '/asset-swap',
    title: messages.swap,
    isVisible: isFeatureEnabled.liquiditySwap,
  },
  {
    link: '/staking',
    title: messages.stake,
    isVisible: () => true,
    isSpanishMoreButton: true,
  },
];

export const daoNavigation: Navigation[] = [
  {
    link: 'https://community.radiant.capital',
    title: messages.discussion,
    absolute: true,
  },
  {
    link: 'https://dao.radiant.capital',
    title: messages.governance,
    absolute: true,
  },
];

export const moreNavigation: Navigation[] = [...moreMenuItems, ...moreMenuExtraItems];

export const mobileNavigation: Navigation[] = [
  ...navigation,
  ...daoNavigation,
  ...moreMenuMobileOnlyItems,
];

export default navigation;
