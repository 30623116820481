import { ReactNode } from 'react';
import classNames from 'classnames';
import { useThemeContext } from '@aave/aave-ui-kit';

import animationCircle from 'images/animationCircle.svg';
import staticStyles from './style';

interface CaptionProps {
  title: string;
  description?: string | ReactNode;
  color?: 'primary' | 'secondary' | 'purple' | 'dark' | 'white';
  className?: string;
  marginBottom?: number;
  withAnimationCircle?: boolean;
  onWhiteBackground?: boolean;
}

export default function Caption({
  title,
  description,
  color = 'primary',
  className,
  marginBottom,
  withAnimationCircle,
  onWhiteBackground,
}: CaptionProps) {
  const { currentTheme } = useThemeContext();

  return (
    <div
      className={classNames('Caption', `Caption__${color}`, className)}
      style={{ marginBottom: `${marginBottom}px` }}
    >
      <h2 className={classNames({ Caption__titleWithCircle: withAnimationCircle })}>
        {title} {withAnimationCircle && <img src={animationCircle} alt="" />}
      </h2>

      {description && (
        <div className={classNames('Caption__description', `Caption__description-${color}`)}>
          {description}
        </div>
      )}

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .Caption {
          &__market {
            h2 {
              color: ${currentTheme.primary.hex};
            }
          }

          &__purple {
            h2 {
              color: ${currentTheme.purple.hex};
            }
          }

          &__primary {
            h2 {
              color: ${currentTheme.primary.hex};
            }
          }

          &__secondary {
            h2 {
              color: ${currentTheme.secondary.hex};
            }
          }

          &__dark {
            h2 {
              color: ${onWhiteBackground
                ? currentTheme.darkBlue.hex
                : currentTheme.textDarkBlue.hex};
            }
          }

          &__white {
            h2 {
              color: ${currentTheme.white.hex};
            }
          }

          &__description {
            color: ${onWhiteBackground ? currentTheme.darkBlue.hex : currentTheme.textDarkBlue.hex};
          }

          &__description-white {
            color: ${currentTheme.white.hex};
          }
        }
      `}</style>
    </div>
  );
}
