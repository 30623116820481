import { ReactNode } from 'react';
import classNames from 'classnames';

import staticStyles from './style';

interface RepayWithdrawWrapperProps {
  title: string;
  children: ReactNode;
  className?: string;
}

export default function RepayWithdrawWrapper({
  // title,
  children,
  className,
}: RepayWithdrawWrapperProps) {
  return (
    <div className={classNames('RepayWithdrawWrapper', className)}>
      <div className="RepayWithdrawWrapper__content">{children}</div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
