import { defineMessages } from 'react-intl';

export default defineMessages({
  assets: `Assets`,
  marketSize: `Market Size`,
  totalBorrowed: `Total Borrowed`,
  netAPY: `Net APY`,
  depositAPY: `Deposit APY`,
  borrowAPY: `Borrow APY`,
  stable: 'Stable',
  variable: 'Variable',
  myDeposit: 'My Deposit',
});
