import { useCallback, useEffect, useState } from 'react';

import { useProtocolDataContext } from 'libs/protocol-data-provider';
import DefaultButton from 'components/basic/DefaultButton';
import CheckBoxField from 'components/fields/CheckBoxField';
import closeIcon from 'images/close.png';
import staticStyles from './style';

const OFFSET_DAYS = 30;

interface BuyRDNTProps {
  setOpenModal: (openModal: boolean) => void;
}

const BUY_RNDT_LINK =
  'https://swap.defillama.com/?chain=arbitrum&from=0x0000000000000000000000000000000000000000&to=0x0c4681e6c0235179ec3d4f4fc4df3d14fdd96017';

export default function BuyRDNTModal({ setOpenModal }: BuyRDNTProps) {
  const { chainId } = useProtocolDataContext();
  const [openBuyRDNTModal, setOpenBuyRDNTModal] = useState(false);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    const hideBuyRDNTModal = localStorage.getItem('hideBuyRDNTModal');
    const hideBuyRDNTModalDate = hideBuyRDNTModal ? new Date(hideBuyRDNTModal) : new Date();

    if (hideBuyRDNTModalDate <= new Date()) {
      setOpenBuyRDNTModal(true);
    } else {
      window.open(BUY_RNDT_LINK, '_blank');
      setOpenModal(false);
    }
  }, []);

  const agreeButtonHandler = useCallback(() => {
    let agreeDate = new Date();

    if (agree) {
      agreeDate.setDate(agreeDate.getDate() + OFFSET_DAYS);
    }

    localStorage.setItem('hideBuyRDNTModal', agreeDate.toString());
    window.open(BUY_RNDT_LINK, '_blank');
    setOpenBuyRDNTModal(false);
    setOpenModal(false);
  }, [agree, chainId, setOpenBuyRDNTModal, setOpenModal]);

  const closeButtonHandler = useCallback(() => {
    setOpenBuyRDNTModal(false);
    setOpenModal(false);
  }, [setOpenBuyRDNTModal, setOpenModal]);

  return (
    <>
      {openBuyRDNTModal && (
        <div className={`buy-rdnt-modal`}>
          <div className={`buy-rdnt-modal-body`}>
            <img
              src={closeIcon}
              alt="close-icon"
              className="buy-rdnt-close-icon"
              onClick={closeButtonHandler}
            />
            <h3>Heading to DefiLlama</h3>
            <p className="desc">
              You are leaving <b>Radiant.Capital</b> and will be redirected to a community run,
              decentralized exchange aggregator that is not managed by Radiant Capital.
            </p>

            <CheckBoxField
              value={agree}
              name="BuyRDNTModal__checkbox"
              onChange={() => setAgree(!agree)}
              title="I have read and agree to this message, don't show it again for 30 days."
            />

            <DefaultButton
              onClick={agreeButtonHandler}
              color="purple"
              title="Continue"
              size="big"
            />
          </div>
        </div>
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
