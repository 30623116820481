import React, { useContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';

import SUSHI_LP_ABI from './sushiswap/sushi-lp-abi.json';

const SUSHI_WETH_USDC_LP_ADDRESS = '0x905dfcd5649217c42684f23958568e533c711aa3';
const SUSHI_WETH_RDNT_LP_ADDRESS = '0x24704aFF49645D32655A76Df6d407E02d146dAfC';

type RdntPricesProviderContext = {
  prices: {
    tokenPrice?: number;
    lpTokenPrice?: number;
    nativePrice?: number;
  };
};

const Context = React.createContext<RdntPricesProviderContext>({} as RdntPricesProviderContext);

export const RdntPricesProvider: React.FC = ({ children }) => {
  const [prices, setPrices] = useState<{
    tokenPrice?: number;
    lpTokenPrice?: number;
    nativePrice?: number;
  }>({});

  useEffect(() => {
    const getData = async () => {
      try {
        const provider = new ethers.providers.JsonRpcProvider(
          'https://rpc.radiant.capital/70ff72eec58b50f824282a0c28f3434d585c9410/'
        );
        const sushiWethUsdcLpContract = new ethers.Contract(
          SUSHI_WETH_USDC_LP_ADDRESS,
          SUSHI_LP_ABI,
          provider
        );
        const sushiWethRdntLpContract = new ethers.Contract(
          SUSHI_WETH_RDNT_LP_ADDRESS,
          SUSHI_LP_ABI,
          provider
        );
        const [wethUsdcReserves, wethRdntReserves, wethRdntTotalSupply] = await Promise.all([
          sushiWethUsdcLpContract.getReserves(),
          sushiWethRdntLpContract.getReserves(),
          sushiWethRdntLpContract.totalSupply(),
        ]);

        const wethPrice =
          (wethUsdcReserves._reserve1._hex * 10 ** 12) / wethUsdcReserves._reserve0._hex;
        const rdntPrice = wethRdntReserves._reserve1._hex / wethRdntReserves._reserve0._hex;
        const rdntUsdcPrice = wethPrice * rdntPrice;
        const lpTokenPrice =
          (wethRdntReserves._reserve1._hex * wethPrice +
            wethRdntReserves._reserve0._hex * rdntUsdcPrice) /
          wethRdntTotalSupply;

        setPrices({ tokenPrice: rdntUsdcPrice, lpTokenPrice });
      } catch (error) {
        console.log('useRdntPrices: Error => ', error);
      }
    };

    getData();
    const intervalId = setInterval(getData, 30 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Context.Provider
      value={{
        prices,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useRdntPrices = () => useContext(Context);
