import React, { useContext, useState, useEffect } from 'react';

import { useStaticPoolDataContext } from 'libs/pool-data-provider';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import { MasterChefContract } from '../MasterChef/MasterChefContract';
import { getProvider } from 'helpers/config/markets-and-network-config';

type RdntEthStakeProviderContext = {
  userStakedAmount: string;
};

const Context = React.createContext<RdntEthStakeProviderContext>({} as RdntEthStakeProviderContext);

export const RdntEthStakeProvider: React.FC = ({ children }) => {
  const { userId } = useStaticPoolDataContext();
  const { chainId, currentMarketData } = useProtocolDataContext();
  const [userStakedAmount, setUserStakedAmount] = useState('0');

  useEffect(() => {
    if (!userId) {
      return;
    }

    const masterChefContract = new MasterChefContract(
      getProvider(chainId),
      currentMarketData.addresses.stakingToken,
      currentMarketData.addresses.masterChef
    );

    const getData = async () => {
      try {
        const _userStakedAmount = await masterChefContract.getUserAmount(userId);
        setUserStakedAmount(_userStakedAmount.toString());
      } catch (error) {
        console.log('useRdntethStake => Error: ', error);
      }
    };

    document.addEventListener('stakeTxnConfirmed', () => {
      getData();
    });

    getData();
    const intervalId = setInterval(getData, 60 * 1000);
    return () => clearInterval(intervalId);
  }, [userId]);

  return (
    <Context.Provider
      value={{
        userStakedAmount,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useRdntethStake = () => useContext(Context);
