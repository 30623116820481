import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .TextStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__text {
      font-size: $regular;
      color: #7159ff;
      font-weight: bold;
    }

    .DefaultButton.TextStatus__button {
      min-height: 40px;
      font-size: $medium;
      padding-left: 10px;
      padding-right: 10px;
      width: fit-content;
    }
  }
`;

export default staticStyles;
