import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { gradient, rgba, useThemeContext } from '@aave/aave-ui-kit';

import { useStaticPoolDataContext } from 'libs/pool-data-provider';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import ScreenWrapper from 'components/wrappers/ScreenWrapper';
import ContentWrapper from 'components/wrappers/ContentWrapper';
import DefaultButton from 'components/basic/DefaultButton';
import Link from 'components/basic/Link';
import LabeledSwitcher from 'components/basic/LabeledSwitcher';
import NoDataPanel from 'components/NoDataPanel';
import MainnetWarning from 'components/MainnetWarning';
import StakeTopPanel from 'components/StakeTopPanel';
import messages from './messages';
import staticStyles from './style';

interface StakingWrapperProps {
  children: ReactNode;
}

export default function StakingWrapper({ children }: StakingWrapperProps) {
  const intl = useIntl();
  const history = useHistory();
  const { currentTheme } = useThemeContext();
  const { userId } = useStaticPoolDataContext();
  const { chainId, currentMarketData } = useProtocolDataContext();

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const isUnStake = history.location.pathname.includes('unstake');
    setToggle(isUnStake);
  }, [history.location.pathname, setToggle]);

  const toggleHandler = useCallback(() => {
    let router = '/staking';
    if (!toggle) {
      router = '/staking/rdntweth/unstake';
    }
    history.push(router);
    setToggle((prev) => !prev);
  }, [toggle, setToggle, history]);

  const goBackHandler = useCallback(() => {
    history.push('/staking');
  }, [history]);

  const gradientBackground = gradient(
    90,
    `${currentTheme.secondary.rgb}, 1`,
    0,
    `${currentTheme.primary.rgb}, 1`,
    100
  );
  const disabledColor = rgba(`${currentTheme.textDarkBlue.rgb}, 0.2`);

  return (
    <ScreenWrapper
      className="StakingWrapper"
      pageTitle="Stake Radiant LP (Pool2)"
      isTopLineSmall={true}
      isTitleOnDesktop={true}
    >
      <p>Stake your RDNT/WETH LP tokens to earn Radiant rewards</p>

      <StakeTopPanel />

      <MainnetWarning />

      <div className="StakingWrapper__content-inner">
        <ContentWrapper
          goBack={goBackHandler}
          withBackButton={true}
          className={classNames('StakingWrapper__content-left', {
            StakingWrapper__contentLeftActive: true,
          })}
        >
          {!userId ? (
            <NoDataPanel
              title={intl.formatMessage(messages.noWalletConnect)}
              description={intl.formatMessage(messages.noWalletConnectDescription)}
              withConnectButton={true}
            />
          ) : (
            <>
              <LabeledSwitcher
                leftOption={intl.formatMessage(messages.stakeToggle)}
                rightOption={intl.formatMessage(messages.unstakeToggle)}
                onToggle={toggleHandler}
                value={toggle}
                darkOnDarkMode={true}
                className="StakingWrapper__switcher"
              />
              {children}
            </>
          )}
        </ContentWrapper>

        <div
          className={classNames('StakingWrapper__content-right', {
            StakingWrapper__contentRightActive: true,
          })}
        >
          <div>
            <h2>About Pool2</h2>
            <p className="description mt-10">
              There are two major benefits to staking RDNT/WETH LP tokens:
            </p>
            <ul className="description">
              <li>1. Earn additional RDNT rewards.</li>
              <li>
                2. Ensure the longevity and health of the protocol by enabling deeper liquidity.
              </li>
            </ul>
            <p className="description mt-10">
              {'To continue, create a RDNT/WETH liquidity position on '}
              <Link
                to={`https://app.sushi.com/legacy/add/0x82aF49447D8a07e3bd95BD0d56f35241523fBab1/${
                  currentMarketData?.addresses?.rdntToken || ''
                }/?chainId=${chainId}`}
                absolute
                inNewWindow
              >
                SushiSwap
              </Link>
              , then return here to stake your LP tokens and begin earning RDNT emissions.
            </p>
            <p className="description mt-10">
              {'It is recommended that you understand the '}
              <Link to={'https://www.youtube.com/watch?v=8XJ1MSTEuU0'} absolute inNewWindow>
                risks of impermanent loss
              </Link>
              {' before staking in Pool2.'}
            </p>
            <Link
              to={`https://app.sushi.com/legacy/add/0x82aF49447D8a07e3bd95BD0d56f35241523fBab1/${
                currentMarketData?.addresses?.rdntToken || ''
              }/?chainId=${chainId}`}
              absolute
              inNewWindow
              className="Markets__top-content__buy mt-10 full-width"
            >
              <DefaultButton title={'Add Liquidity'} size="big" color="purple" />
            </Link>
          </div>
        </div>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        @import 'src/_mixins/screen-size';

        .StakingWrapper {
          &__content-left,
          &__content-right {
            background: ${currentTheme.whiteElement.hex};
          }

          &__info-timerWrapper {
            color: ${currentTheme.textDarkBlue.hex};
          }
          &__info-timerInner {
            background: ${currentTheme.mainBg.hex};
            @include respond-to(sm) {
              background: ${currentTheme.disabledGray.hex};
            }
            .StakingWrapper__timer {
              color: ${currentTheme.textDarkBlue.hex};
            }
          }

          &__gradientButton {
            background: ${gradientBackground};
            color: ${currentTheme.white.hex};

            &:disabled,
            &:disabled &:hover {
              .StakingWrapper__gradientButton-inner {
                background: ${currentTheme.mainBg.hex};
                color: ${disabledColor};
                @include respond-to(sm) {
                  background: ${currentTheme.disabledGray.hex};
                }
              }
            }
            &:after {
              background: ${gradientBackground};
            }
          }
          &__gradientButton-inner {
            background: ${gradientBackground};
          }

          .StakingWrapper__unstakeTimer {
            color: ${currentTheme.textDarkBlue.hex};
          }
        }
      `}</style>
    </ScreenWrapper>
  );
}
