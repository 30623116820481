import { useIntl } from 'react-intl';

import MobileCardWrapper from 'components/wrappers/MobileCardWrapper';
import Row from 'components/basic/Row';
import Value from 'components/basic/Value';
import LiquidityMiningCard from 'components/liquidityMining/LiquidityMiningCard';
import NoData from 'components/basic/NoData';
import Link from 'components/basic/Link';
import DefaultButton from 'components/basic/DefaultButton';
import AMPLWarning from 'components/AMPLWarning';
import defaultMessages from 'defaultMessages';
import messages from './messages';
import { BorrowTableItem } from './types';

export default function BorrowMobileCard({
  reserve: { symbol },
  currentBorrows,
  currentBorrowsUSD,
  borrowRate,
  avg30DaysVariableRate,
  rdntRewardsBorrowApr,
  isActive,
  isFrozen,
  borrowingEnabled,
  repayLink,
  borrowLink,
}: BorrowTableItem) {
  const intl = useIntl();

  return (
    <>
      <MobileCardWrapper symbol={symbol}>
        <Row title={intl.formatMessage(messages.secondTableColumnTitle)} withMargin={true}>
          <Value
            value={Number(currentBorrows)}
            subValue={Number(currentBorrowsUSD)}
            subSymbol="USD"
            maximumSubValueDecimals={2}
            maximumValueDecimals={2}
            minimumValueDecimals={2}
          />
        </Row>

        <Row title={intl.formatMessage(messages.apyRowTitle)} withMargin={true}>
          {borrowingEnabled ? (
            <LiquidityMiningCard
              symbol={symbol}
              value={Number(borrowRate)}
              thirtyDaysValue={avg30DaysVariableRate}
              liquidityMiningValue={rdntRewardsBorrowApr}
              type={'borrow-variable'}
            />
          ) : (
            <NoData color="dark" />
          )}
        </Row>

        <Row
          title={intl.formatMessage(messages.borrowMore)}
          className="Row__center"
          withMargin={true}
        >
          <Link
            to={borrowLink}
            className="ButtonLink"
            disabled={!isActive || !borrowingEnabled || isFrozen}
          >
            <DefaultButton
              title={intl.formatMessage(defaultMessages.borrow)}
              color="purple"
              disabled={!isActive || !borrowingEnabled || isFrozen}
            />
          </Link>
        </Row>

        <Row title={intl.formatMessage(messages.repayYourBorrow)} className="Row__center">
          <Link to={repayLink} className="ButtonLink" disabled={!isActive}>
            <DefaultButton
              title={intl.formatMessage(defaultMessages.repay)}
              color="dark"
              transparent={true}
              disabled={!isActive}
            />
          </Link>
        </Row>
      </MobileCardWrapper>

      {symbol === 'AMPL' && <AMPLWarning />}
    </>
  );
}
