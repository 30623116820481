import { defineMessages } from 'react-intl';

export default defineMessages({
  total: 'Market Cap',
  token: 'RDNT Price',
  tokenDescription: 'The price of RDNT directly affects the APR for Locking and Pool2.',
  locked: 'RDNT Locked',
  circulating: 'Circulating supply',
  lockApr: 'Locking APR',
  lockAprDescription:
    'The Annual Percentage Rate, dictated by the total amount of RDNT locked, and by protocol revenue generated from borrowing interest fees and early exit penalties.',
  pool2Apr: 'Pool2 APR',
  pool2AprDescription:
    'APR from RDNT/WETH liquidity, paid out in RDNT emissions. 20% of all global RDNT emissions are allocated to Pool2.',
});
