import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .RdntBalance {
    display: flex;
    align-items: center;

    @include respond-to(sm) {
      width: 100%;
      justify-content: center;
    }

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin: 8px;

      @include respond-to(sm) {
        width: 30px;
        height: 30px;
      }
    }

    p {
      font-size: 14px;
      color: #ffffff !important;

      @include respond-to(sm) {
        font-size: 18px;
      }
    }
  }
`;

export default staticStyles;
