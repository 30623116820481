import { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { rgba, useThemeContext, DropdownWrapper } from '@aave/aave-ui-kit';

import { useUserWalletDataContext } from 'libs/web3-data-provider';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import Link from 'components/basic/Link';
import { daoNavigation } from '../navigation';
import messages from './messages';
import staticStyles from './style';

export default function DaoButton() {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { currentAccount } = useUserWalletDataContext();
  const { currentMarketData } = useProtocolDataContext();

  const [visible, setVisible] = useState(false);

  const borderColor = rgba(`${currentTheme.darkBlue.rgb}, 0.1`);
  const hoverColor = rgba(`${currentTheme.darkBlue.rgb}, 0.05`);

  return (
    <DropdownWrapper
      visible={visible}
      setVisible={setVisible}
      className="DaoButton"
      horizontalPosition="right"
      verticalPosition="bottom"
      buttonComponent={
        <button
          className={classNames('DaoButton__button', { DaoButton__buttonActive: visible })}
          onClick={() => setVisible(!visible)}
          type="button"
        >
          <span>{intl.formatMessage(messages.more)}</span>
          <strong>{intl.formatMessage(messages.more)}</strong>
        </button>
      }
    >
      <div className="DaoButton__content">
        <ul className="DaoButton__links">
          {daoNavigation.map((link, index) => (
            <li
              className={classNames('DaoButton__link-inner', {
                DaoButton__linkHidden:
                  (!currentAccount && link.hiddenWithoutWallet) ||
                  (link.isVisible && !link.isVisible(currentMarketData)),
              })}
              key={index}
            >
              <Link
                className="DaoButton__link ButtonLink"
                to={link.link}
                inNewWindow={link.absolute}
                absolute={link.absolute}
                onClick={() => setVisible(false)}
              >
                <p>{intl.formatMessage(link.title)}</p>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .DaoButton {
          &__button {
            color: ${currentTheme.white.hex};
          }

          &__links {
            li {
              border-bottom: 1px solid ${borderColor};
            }
          }

          &__link {
            color: ${currentTheme.darkBlue.hex} !important;
            &:hover {
              background: ${hoverColor};
            }
          }
        }
      `}</style>
    </DropdownWrapper>
  );
}
