import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .MainDashboardTable {
    margin-top: 45px;
    margin-bottom: 10px;

    &__rdnt {
      margin-bottom: 35px;

      .TableHeader__item {
        max-width: none !important;
        flex: 1 1 0 !important;
      }

      .TableCol,
      .TableButtonsWrapper {
        max-width: none !important;
        flex: 1 1 0 !important;
      }
    }

    &__inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      flex: 1;

      @include respond-to(lg) {
        flex-direction: column;
        justify-content: flex-start;
        display: block;
      }
    }

    &__left-inner,
    &__right-inner {
      width: calc(50% - 15px); /* 30px between tables */
      display: flex;
      align-self: stretch;
      flex-direction: column;
      @include respond-to(lg) {
        width: 100%;
        flex: none;
        min-height: auto;
        display: block;
      }
      @include respond-to(sm) {
        flex: 1;
      }
    }

    &__left-inner {
      @include respond-to(lg) {
        margin-bottom: 20px;
      }
      @include respond-to(md) {
        margin-bottom: 30px;
      }

      .TableHeader__item:nth-of-type(2) {
        p {
          white-space: nowrap;
        }
      }
    }

    .TableHeader__title,
    .TableHeader .TextWithModal__text {
      font-size: $small;
    }

    &__noBorrows {
      .MainDashboardTable__left-inner {
        width: 100%;
      }

      .MainDashboardTable__right-inner {
        width: 100%;
      }

      .ContentWrapper {
        margin: 32px 0 0;
        @include respond-to(xl) {
          margin: 26px 0 0;
        }
        @include respond-to(lg) {
          margin: 0;
        }
        @include respond-to(sm) {
          padding: 0 10px;
        }
      }
    }
  }

  @media (max-height: 750px) and (max-width: 1200px) {
    .MainDashboardTable {
      display: block;
    }
  }
`;

export default staticStyles;
