import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import queryString from 'query-string';

import { MasterChefContract } from 'libs/aave-protocol-js/MasterChef/MasterChefContract';
import { useRdntethStake } from 'libs/aave-protocol-js/hooks/use-rdnteth-stake';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import BasicForm from 'components/forms/BasicForm';
import { getProvider } from 'helpers/config/markets-and-network-config';
import messages from './messages';

export default function UnstakeAmount() {
  const intl = useIntl();
  const history = useHistory();
  const { chainId, currentMarketData } = useProtocolDataContext();
  const { userStakedAmount } = useRdntethStake();

  const handleSubmit = (amount: string) => {
    const query = queryString.stringify({ amount });
    history.push(`/staking/rdntweth/unstake/confirmation?${query}`);
  };

  const handleGetTransactions = (userId: string) => async () => {
    const masterChefContract = new MasterChefContract(
      getProvider(chainId),
      currentMarketData.addresses.stakingToken,
      currentMarketData.addresses.masterChef
    );
    return masterChefContract.withdraw(userId, userStakedAmount);
  };

  const lpLinkHandler = useCallback(() => {
    window.open(
      `https://app.sushi.com/legacy/add/0x82aF49447D8a07e3bd95BD0d56f35241523fBab1/${
        currentMarketData?.addresses?.rdntToken || ''
      }/?chainId=${chainId}`,
      '_blank'
    );
  }, [currentMarketData, chainId]);

  return (
    <>
      <BasicForm
        title={intl.formatMessage(messages.caption)}
        description={intl.formatMessage(messages.description, {
          uniSwap: (
            <strong onClick={lpLinkHandler} className={'lp-link'}>
              {' '}
              {intl.formatMessage(messages.uniSwap)}{' '}
            </strong>
          ),
        })}
        amountFieldTitle={intl.formatMessage(messages.currentlyStaked)}
        maxAmount={userStakedAmount}
        currencySymbol={`RDNT/WETH`}
        onSubmit={handleSubmit}
        submitButtonTitle={intl.formatMessage(messages.unstake)}
        getTransactionData={handleGetTransactions}
      />
      <style jsx={true} global={true}>
        {`
          .lp-link {
            cursor: pointer;
            text-decoration: underline;
          }
        `}
      </style>
    </>
  );
}
