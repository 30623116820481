import { defineMessages } from 'react-intl';

export default defineMessages({
  depositInformation: 'Deposit Information',
  approximateBalance: 'Balance',
  depositComposition: 'Deposit Composition',
  borrowingPowerAvailable: 'Borrowing Power Available',

  borrowInformation: 'Borrow Information',
  youBorrowed: 'Balance',
  yourCollateral: 'Your Collateral',
  currentLTV: 'Current LTV',
  borrowingPowerUsed: 'Borrowing Power Used',
  borrowComposition: 'Borrow Composition',
  details: 'Details',

  noDeposits: 'No deposits yet',
  connectWallet: 'Please connect your wallet',

  claimButton: 'Claim',
  vestLock: '1-Click Vest + Lock',
  vestLockDescription: 'Earn {lockingApr}% by locking your RDNT now',
  vestLockTooltip: 'Instantly Vest & Exit your pending RDNT to start earning the Lock APR',
  startVesting: 'Start Vesting',

  helpIconRewards:
    'Lend or borrow to earn RDNT rewards. All rewards must be vested in order to be claimed. You may claim vested RDNT rewards on the "Manage Radiant" page.',
});
