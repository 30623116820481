import { defineMessages } from 'react-intl';

export default defineMessages({
  caption: 'How much would you like to unstake?',
  description:
    'Unstake your LP token here. After unstaking, you can then unwind your liquidity from {uniSwap} back into the base currencies. When you unstake from Pool2, you will no longer earn RDNT rewards.',
  uniSwap: 'SushiSwap',
  currentlyStaked: 'Currently staked',
  unstake: 'Unstake',
});
