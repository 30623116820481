import staticStyles from './style';
import LoadingLogo from 'images/radiant/loading-logo.svg';

interface LoadingSpinnerProps {
  loading: boolean;
  size?: number;
}

export default function LoadingSpinner({ loading, size = 200 }: LoadingSpinnerProps) {
  if (!loading) return null;

  return (
    <div className={'LoadingSpinner'}>
      <img alt="loading-spinner" src={LoadingLogo} />

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
