import { ChainId } from '@aave/contract-helpers';

import { MarketDataType } from 'helpers/config/types';
import { networkConfigs } from 'ui-config/networks';
import * as logos from './images';

export enum CustomMarket {
  arbitrum_one = 'arbitrum_one',
  arbitrum_rinkeby = 'arbitrum_rinkeby',
  local = 'local',
}

export const marketsData: { [key in keyof typeof CustomMarket]: MarketDataType } = {
  [CustomMarket.arbitrum_one]: {
    chainId: ChainId.arbitrum_one,
    logo: logos.radiant,
    activeLogo: logos.radiantActive,
    subLogo: logos.arbitrum,
    aTokenPrefix: 'R',
    enabledFeatures: {
      faucet: false,
      incentives: false,
    },
    addresses: networkConfigs[ChainId.arbitrum_one].addresses,
  },
  [CustomMarket.arbitrum_rinkeby]: {
    chainId: ChainId.arbitrum_rinkeby,
    logo: logos.radiant,
    activeLogo: logos.radiantActive,
    subLogo: logos.arbitrum,
    aTokenPrefix: 'R',
    enabledFeatures: {
      faucet: true,
      incentives: false,
    },
    addresses: networkConfigs[ChainId.arbitrum_rinkeby].addresses,
  },
  [CustomMarket.local]: {
    chainId: ChainId.local,
    logo: logos.radiant,
    activeLogo: logos.radiantActive,
    subLogo: logos.arbitrum,
    aTokenPrefix: 'R',
    enabledFeatures: {
      faucet: true,
      incentives: false,
    },
    addresses: networkConfigs[ChainId.local].addresses,
  },
} as const;
