import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Borrow1ClickLoopForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border: solid 1px rgba(255, 255, 255, 0.5);
    background: #fff;
    padding: 30px;

    &__title {
      font-size: 32px;
      margin-bottom: 20px;
    }

    &__description {
      width: 100%;
      max-width: 660px;
      text-align: center;
      color: #8d8f99;
      margin-bottom: 20px;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 15px;
      width: 100%;

      p {
        font-size: 12px;
        text-align: center;
      }
    }

    &__select {
      margin-top: 20px;

      p {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }

    &__select-button {
      display: flex;
      align-items: center;
      font-size: 16px;
      padding: 10px;
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__status-label {
      b {
        font-weight: 400;
      }
    }
  }
`;

export default staticStyles;
