import { FormEvent, useCallback, useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import BigNumber from 'bignumber.js';
import { valueToBigNumber } from '@aave/protocol-js';

import { ComputedReserveData } from 'libs/pool-data-provider';
import { useUserWalletDataContext } from 'libs/web3-data-provider';
import { useWalletBalanceProviderContext } from 'libs/wallet-balance-provider/WalletBalanceProvider';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import { LeveragerContract } from 'libs/aave-protocol-js/Leverager/LeveragerContract';
import useRdntLendingPoolRewards from 'libs/aave-protocol-js/hooks/use-rdnt-lending-pool-rewards';
import { getProvider } from 'helpers/config/markets-and-network-config';
import PoolTxConfirmationView from 'components/PoolTxConfirmationView';
import { ValidationWrapperComponentProps } from 'components/RouteParamsValidationWrapper';
import AmountField from 'components/fields/AmountField';
import DefaultButton from 'components/basic/DefaultButton';
import ConnectButton from 'components/ConnectButton';
import LabeledSwitcher from 'components/basic/LabeledSwitcher';
import SelectTokenField from 'components/fields/SelectTokenField';
import Row from 'components/basic/Row';
import ValuePercent from 'components/basic/ValuePercent';
import InputBar from 'components/basic/InputBar';
import HealthFactor from 'components/HealthFactor';
import { TokenIcon } from 'helpers/config/assets-config';
import {
  BN_ONE,
  significantLoopingCount,
  loopingLeverageToLtv,
  estimateLooping,
} from 'helpers/leverage';
import messages from './messages';
import staticStyles from './style';

interface Borrow1ClickLoopFormProps
  extends Pick<
    ValidationWrapperComponentProps,
    'userReserve' | 'poolReserve' | 'user' | 'currencySymbol'
  > {
  className?: string;
  stableReserves?: ComputedReserveData[];
  setReserveId?: (value: string) => void;
  isDisableTokenSelect?: boolean;
}

const INTEREST_RATE_MODE = '2';
const AMOUNT_MIN = '1';
const LEVERAGE_MIN = '1.1';

export default function Borrow1ClickLoopForm({
  user,
  currencySymbol,
  poolReserve,
  className,
  stableReserves,
  setReserveId = () => {},
  isDisableTokenSelect = false,
}: Borrow1ClickLoopFormProps) {
  const intl = useIntl();
  const { currentAccount } = useUserWalletDataContext();
  const { walletData } = useWalletBalanceProviderContext();
  const { chainId, currentMarketData } = useProtocolDataContext();
  const { getRewardApr } = useRdntLendingPoolRewards();

  let blockingError = '';
  const maxLeverage = BN_ONE.div(BN_ONE.minus(valueToBigNumber(poolReserve.baseLTVasCollateral)))
    .decimalPlaces(2, BigNumber.ROUND_FLOOR)
    .toString();

  const { rdntRewardsDepositApr = 0, rdntRewardsBorrowApr = 0 } = getRewardApr(poolReserve);

  const [tab, setTab] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [formData, setFormData] = useState({
    amount: AMOUNT_MIN,
    leverage: maxLeverage,
  });
  const [errors, setErrors] = useState({
    amount: '',
    leverage: '',
  });
  const [chainSelectVisible, setChainSelectVisible] = useState(false);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      leverage: maxLeverage,
    }));
  }, [maxLeverage]);

  const { depositAPY, borrowAPY, rewardAPR, netAPY, healthFactor } = estimateLooping({
    amount: valueToBigNumber(formData.amount),
    asset: poolReserve,
    leverage: valueToBigNumber(formData.leverage),
    depositIncentiveAPR: valueToBigNumber(rdntRewardsDepositApr),
    variableBorrowIncentiveAPR: valueToBigNumber(rdntRewardsBorrowApr),
    userSummary: user,
  });

  const walletBalance = useMemo(() => {
    const maxWalletBalance =
      walletData[poolReserve.underlyingAsset] === '0'
        ? valueToBigNumber('0')
        : valueToBigNumber(walletData[poolReserve.underlyingAsset] || '0').dividedBy(
            valueToBigNumber('10').pow(poolReserve.decimals)
          );

    return maxWalletBalance.toString(10);
  }, [walletData, poolReserve]);

  const tabHandler = useCallback(() => {
    setTab((prev) => !prev);
  }, [setTab]);

  const inputHandler = useCallback(
    (key: string, maxValue: string, minValue: string) => (value: string) => {
      if (maxValue && parseFloat(value) > parseFloat(maxValue)) {
        value = maxValue;
      }

      if (minValue && parseFloat(value) < parseFloat(minValue)) {
        setErrors((prev) => ({
          ...prev,
          [key]: `This field should be more than ${minValue}`,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [key]: '',
        }));
      }

      setFormData((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    [setFormData, setErrors]
  );

  const handleBarChange = useCallback(
    (amount: string) => {
      setFormData((prev) => ({
        ...prev,
        leverage: amount,
      }));
    },
    [setFormData]
  );

  const handleMaxButtonClick = useCallback(
    (key: string, maxValue: string) => () => {
      setFormData((prev) => ({
        ...prev,
        [key]: maxValue,
      }));
    },
    [setFormData]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (parseFloat(formData.amount) <= 0) {
        setErrors((prev) => ({
          ...prev,
          amount: 'Please input the correct amount',
        }));
        return;
      }

      if (!errors.leverage) {
        setIsConfirm(true);
      }
    },
    [errors, formData, setIsConfirm, setErrors]
  );

  const handleGetTransactions = useCallback(async () => {
    const leveragerContract = new LeveragerContract(
      getProvider(chainId),
      currentMarketData.addresses.leverager
    );

    const userId = user?.id || '';
    const assetAddress = poolReserve.underlyingAsset;
    const debtTokenAddress = poolReserve.variableDebtTokenAddress;
    const amount = formData.amount;
    const borrowRatio = loopingLeverageToLtv(valueToBigNumber(formData.leverage));
    const loopCount = significantLoopingCount(valueToBigNumber(formData.leverage));

    return await leveragerContract.loop(
      userId,
      assetAddress,
      debtTokenAddress,
      amount,
      INTEREST_RATE_MODE,
      borrowRatio.toString(),
      loopCount.toString()
    );
  }, [user, poolReserve, formData]);

  const handleMainTxExecuted = () => {};

  return (
    <div className={classNames('Borrow1ClickLoopForm', className)}>
      <h2 className="Borrow1ClickLoopForm__title">{intl.formatMessage(messages.title)}</h2>

      <p className="Borrow1ClickLoopForm__description">
        {intl.formatMessage(messages.description)}
      </p>
      {false && (
        <LabeledSwitcher
          leftOption={intl.formatMessage(messages.oneClickTab)}
          rightOption={intl.formatMessage(messages.closeTab)}
          onToggle={tabHandler}
          value={tab}
          darkOnDarkMode={true}
        />
      )}

      {!tab ? (
        !isConfirm ? (
          <form onSubmit={handleSubmit} className="Borrow1ClickLoopForm__inner">
            {!isDisableTokenSelect && (
              <SelectTokenField
                className="Borrow1ClickLoopForm__select-field"
                visible={chainSelectVisible}
                setVisible={setChainSelectVisible}
                placeholder={intl.formatMessage(messages.selectToken)}
                value={poolReserve}
              >
                {stableReserves?.map((item) => (
                  <button
                    className="Borrow1ClickLoopForm__select-button"
                    type="button"
                    onClick={() => {
                      setReserveId(item.id);
                      setChainSelectVisible(false);
                    }}
                    disabled={poolReserve.id === item.id}
                    key={item.id}
                  >
                    <TokenIcon tokenSymbol={item.symbol} height={30} width={30} />
                    <span>{item.symbol}</span>
                  </button>
                ))}
              </SelectTokenField>
            )}

            <AmountField
              title={intl.formatMessage(messages.amount)}
              maxAmount={walletBalance}
              symbol={currencySymbol}
              maxDecimals={poolReserve.decimals}
              value={formData.amount}
              onChange={inputHandler('amount', walletBalance, AMOUNT_MIN)}
              onMaxButtonClick={handleMaxButtonClick('amount', walletBalance)}
              error={errors.amount}
            />

            <InputBar
              label={intl.formatMessage(messages.leverage)}
              value={Number(formData.leverage)}
              minAmount={LEVERAGE_MIN}
              maxAmount={maxLeverage}
              onChange={handleBarChange}
            />

            <Row
              title={
                <TokenIcon
                  tokenSymbol={currencySymbol}
                  tokenFullName={'Net APY'}
                  height={20}
                  width={20}
                  tooltipId={currencySymbol}
                  className="Borrow1ClickLoopForm__status-label"
                />
              }
              isColumn={false}
            >
              <ValuePercent value={depositAPY.minus(borrowAPY)} showFullNum />
            </Row>

            <Row
              title={
                <TokenIcon
                  tokenSymbol={'rdnt'}
                  tokenFullName={'Reward APR'}
                  height={20}
                  width={20}
                  tooltipId={currencySymbol}
                  className="Borrow1ClickLoopForm__status-label"
                />
              }
            >
              <ValuePercent value={rewardAPR} showFullNum />
            </Row>

            <Row title={'Estimated Net APY'}>
              <ValuePercent value={netAPY} showFullNum />
            </Row>

            <HealthFactor value={healthFactor || '-1'} titleLightWeight={true} />

            <p>{intl.formatMessage(messages.loopDescription)}</p>

            <div className="Borrow1ClickLoopForm__buttons">
              {!currentAccount ? (
                <ConnectButton />
              ) : (
                <DefaultButton
                  title={intl.formatMessage(messages.submit)}
                  mobileBig={true}
                  size="medium"
                  type="submit"
                  color="purple"
                  disabled={!!errors.leverage}
                />
              )}
            </div>
          </form>
        ) : (
          <>
            <PoolTxConfirmationView
              mainTxName={intl.formatMessage(messages.title)}
              boxTitle={intl.formatMessage(messages.title)}
              boxDescription={intl.formatMessage(messages.boxDescription)}
              getTransactionsData={handleGetTransactions}
              onMainTxExecuted={handleMainTxExecuted}
              blockingError={blockingError}
              mainTxFailedMessage="Try again with lowered loop count or a higher amount."
            />
            <div className="Borrow1ClickLoopForm__buttons">
              <DefaultButton
                title={intl.formatMessage(messages.goBack)}
                mobileBig={true}
                size="medium"
                color="purple"
                onClick={() => setIsConfirm(false)}
              />
            </div>
          </>
        )
      ) : (
        <form onSubmit={handleSubmit} className="Borrow1ClickLoopForm__inner">
          <p>{intl.formatMessage(messages.closeDescription)}</p>

          <div className="Borrow1ClickLoopForm__buttons">
            {!currentAccount ? (
              <ConnectButton />
            ) : (
              <DefaultButton
                title={intl.formatMessage(messages.closeLoopButton)}
                mobileBig={true}
                size="medium"
                type="submit"
                color="purple"
                disabled={true}
              />
            )}
          </div>
        </form>
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
