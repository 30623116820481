import { defineMessages } from 'react-intl';

export default defineMessages({
  markets: 'Markets',
  dashboard: 'Dashboard',
  deposit: 'Deposit',
  borrow: 'Borrow',
  manageRadiant: 'Manage RDNT',
  swap: 'Swap',
  stake: 'Stake',
  buyRdnt: 'Buy RDNT',
  discussion: 'Discussion',
  governance: 'Governance',
  loop: 'Loop',
  migration: 'Migration',
});
