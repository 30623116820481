import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .TokenLockPanel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    &--full-width {
      width: 100%;
    }

    &__child-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      width: 100%;
      padding: 18px;
      margin-bottom: 13px;

      border-radius: 5px;
      border: solid 1px rgba(255, 255, 255, 0.5);

      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #0000000d;
      opacity: 1;
    }

    &__lockTokenDescription {
      font: normal normal normal 12px Montserrat;
      letter-spacing: 0px;
      color: #545454;
      opacity: 1;
      text-align: center;
    }

    &__total-fee {
      color: #000000;
      opacity: 1;
      width: 100%;
      margin-top: 8px;
      text-align: center;
      font-size: 15px;
      font-family: Montserrat, serif;

      &--title {
        display: flex;
        justify-content: center;
      }

      &--amount {
        font: normal normal bold 40px Roboto;
        color: #725dff;
        opacity: 1;
      }
    }

    &__subheader {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      font-size: 17px;
      text-align: center;
      font-family: Montserrat, serif;

      &--bold {
        white-space: nowrap;
        font-weight: bold;
      }

      &--purple {
        color: #7159ff;
      }

      &--big-font {
        font-size: 21px;
      }
    }

    &__buttons-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      width: 100%;
      font-size: 19px;

      @media (max-width: 992px) {
        .container {
          grid-template-columns: auto;
        }
      }
    }

    &__buttons-item {
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      text-align: center;
      padding: 12.5px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .TokenIcon__name {
        font-size: 14px;

        b {
          font-weight: 500;
        }
      }

      &--apr {
        font-family: 'Roboto';
        font-weight: bold;
      }
    }

    &__lock-button {
      width: 100%;
      height: 50px;
      font-size: 20px;
      font-weight: 500;
    }
  }
`;

export default staticStyles;
