import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { useThemeContext } from '@aave/aave-ui-kit';
import css from 'styled-jsx/css';

import { useMenuContext } from 'libs/menu';
import { useStaticPoolDataContext } from 'libs/pool-data-provider';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import {
  Markets,
  ReserveOverview,
  History,
  Deposit,
  Withdraw,
  Borrow,
  ManageRadiant,
  Repay,
  Faucet,
  Dashboard,
  Staking,
  AssetSwap,
  Loop,
} from 'modules';
import SwapBorrowRateModeConfirmation from 'modules/swap/SwapBorrowRateModeConfirmation';
import SwapUsageAsCollateralModeConfirmation from 'modules/swap/SwapUsageAsCollateralModeConfirmation';
import { RewardConfirm } from 'modules/reward/screens/RewardConfirm';
import ScreensWrapper from 'components/wrappers/ScreensWrapper';
import WelcomeModal from 'components/WelcomeModal';
import { CURRENCY_ROUTE_PARAMS } from 'helpers/router-types';
import { isFeatureEnabled } from 'helpers/config/markets-and-network-config';

const staticStyles = css.global`
  .App {
    display: flex;
    flex-direction: column;
    flex: auto;
    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;
    }
  }
`;

function ModulesWithMenu() {
  const { isUserHasDeposits, userId } = useStaticPoolDataContext();
  const { currentMarketData } = useProtocolDataContext();

  localStorage.removeItem('theme');

  return (
    <ScreensWrapper>
      <Switch>
        <Route path="/markets" component={Markets} />

        <Route path="/dashboard" component={Dashboard} />

        <Route path="/deposit" component={Deposit} />

        <Route path={`/withdraw/${CURRENCY_ROUTE_PARAMS}`} component={Withdraw} />

        <Route path="/borrow" component={Borrow} />

        <Route path={`/repay/${CURRENCY_ROUTE_PARAMS}`} component={Repay} />

        <Route path="/manage-radiant" component={ManageRadiant} />

        <Route
          exact={true}
          path={`/interest-swap/${CURRENCY_ROUTE_PARAMS}/confirmation`}
          component={SwapBorrowRateModeConfirmation}
        />

        <Route
          exact={true}
          path={`/usage-as-collateral/${CURRENCY_ROUTE_PARAMS}/confirmation`}
          component={SwapUsageAsCollateralModeConfirmation}
        />

        <Route
          exact={true}
          path={`/reserve-overview/${CURRENCY_ROUTE_PARAMS}`}
          component={ReserveOverview}
        />

        <Route path="/staking" component={Staking} key="Staking" />

        <Route path="/asset-swap" component={AssetSwap} key="AssetSwap" />

        <Route path="/loop" component={Loop} key="Loop" />

        <Route
          path="/rewards/confirm/:incentivesControllerAddress"
          component={RewardConfirm}
          key="Reward confirm"
        />

        {userId && [<Route exact={true} path="/history" component={History} key="History" />]}

        {isFeatureEnabled.faucet(currentMarketData) && [
          <Route path="/faucet" component={Faucet} key="Faucet" />,
        ]}

        <Redirect to={isUserHasDeposits ? '/dashboard' : '/markets'} />
      </Switch>
    </ScreensWrapper>
  );
}

const App: React.FC = () => {
  const { md } = useThemeContext();
  const { openMobileMenu } = useMenuContext();

  const handlers = useSwipeable({
    onSwipedLeft: () => (md ? openMobileMenu() : null),
  });

  return (
    <div className="App">
      <div {...handlers} className="App__content">
        <Switch>
          <Route component={ModulesWithMenu} />
        </Switch>
        <WelcomeModal />
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
};

export default App;
