import { useIntl } from 'react-intl';

import { CompactNumber } from 'components/basic/CompactNumber';
import TextWithTooltip from 'components/TextWithTooltip';
import messages from './messages';
import staticStyles from './style';

interface TotalMarketsSizeProps {
  value: number;
}

export default function TotalMarketsSize({ value }: TotalMarketsSizeProps) {
  const intl = useIntl();

  return (
    <div className="TotalMarketsSize">
      <div className="TotalMarketsSize__title-container">
        <p>{intl.formatMessage(messages.title)}</p>
        <TextWithTooltip text={''} iconSize={14} id="totalMarketSize">
          <p className="modal-text">{intl.formatMessage(messages.description)}</p>
        </TextWithTooltip>
      </div>

      <h2 className="TotalMarketsSize--big">
        $ <CompactNumber value={value} maximumFractionDigits={2} minimumFractionDigits={2} />
      </h2>

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .TotalMarketsSize {
          color: #131313;
        }
      `}</style>
    </div>
  );
}
