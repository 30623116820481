import { useIntl } from 'react-intl';
import { useThemeContext } from '@aave/aave-ui-kit';

import { BorrowRateMode } from 'libs/pool-data-provider/graphql';
import TableItem from 'modules/dashboard/components/DashboardTable/TableItem';
import TableCol from 'modules/dashboard/components/DashboardTable/TableCol';
import TableValueCol from 'modules/dashboard/components/DashboardTable/TableValueCol';
import TableButtonsWrapper from 'modules/dashboard/components/DashboardTable/TableButtonsWrapper';
import TableButtonCol from 'modules/dashboard/components/DashboardTable/TableButtonCol';
import TableAprCol from 'modules/dashboard/components/DashboardTable/TableAprCol';
import defaultMessages from 'defaultMessages';
import { BorrowTableItem } from './types';

export default function BorrowItem({
  reserve: { symbol },
  uiColor,
  currentBorrows,
  currentBorrowsUSD,
  borrowRate,
  avg30DaysVariableRate,
  rdntRewardsBorrowApr,
  borrowRateMode,
  onSwitchToggle,
  isActive,
  isFrozen,
  borrowingEnabled,
  stableBorrowRateEnabled,
  repayLink,
  borrowLink,
  index,
  vincentivesAPR,
  sincentivesAPR,
  ...rest
}: BorrowTableItem) {
  const intl = useIntl();
  const { xl, lg } = useThemeContext();

  return (
    <TableItem tokenSymbol={symbol} color={uiColor} {...rest}>
      <TableValueCol
        value={Number(currentBorrows)}
        subValue={Number(currentBorrowsUSD)}
        tooltipId={`borrow-${symbol}__${index}`}
      />

      <TableAprCol
        value={Number(borrowRate)}
        thirtyDaysAverage={avg30DaysVariableRate}
        liquidityMiningValue={rdntRewardsBorrowApr || 0}
        symbol={symbol}
        type={borrowRateMode === BorrowRateMode.Variable ? 'borrow-variable' : 'borrow-stable'}
      />

      <TableCol maxWidth={xl && !lg ? 140 : '100%'} />

      <TableButtonsWrapper>
        <TableButtonCol
          disabled={!isActive || !borrowingEnabled || isFrozen}
          title={intl.formatMessage(defaultMessages.borrow)}
          linkTo={borrowLink}
        />
        <TableButtonCol
          disabled={!isActive}
          title={intl.formatMessage(defaultMessages.repay)}
          linkTo={repayLink}
          withoutBorder={true}
        />
      </TableButtonsWrapper>
    </TableItem>
  );
}
