import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .BorrowAction {
    padding-top: 15px;

    &__column {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 0 3px;
      &:first-of-type {
        justify-content: flex-end;
      }
      &:nth-of-type(2) {
        @include respond-to(lg) {
          display: none;
        }
        @include respond-to(md) {
          display: flex;
        }
        @include respond-to(xs) {
          display: none;
        }
      }

      &:nth-of-type(3) {
        justify-content: flex-end;
        @include respond-to(md) {
          justify-content: center;
        }
      }

      &:last-of-type {
        flex: 2;
        justify-content: flex-end;
      }

      a {
        width: 100%;
      }
    }

    .BorrowAction__button {
      width: 100%;
      min-height: 32px;
      font-size: $medium;
    }

    &__buttonNoBorder {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #131313;
      @include respond-to(xs) {
        color: white;
      }
    }
  }
`;

export default staticStyles;
