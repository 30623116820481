import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { useProtocolDataContext } from 'libs/protocol-data-provider';
import Row from 'components/basic/Row';
import ContentWrapper from 'components/wrappers/ContentWrapper';
import Value from 'components/basic/Value';
import HealthFactor from 'components/HealthFactor';
import MaxLTVHelpModal from 'components/HelpModal/MaxLTVHelpModal';
import ValuePercent from 'components/basic/ValuePercent';
import RepayWithdrawWrapper from 'components/wrappers/RepayWithdrawWrapper';
import CollateralCompositionBar from 'components/compositionBars/CollateralCompositionBar';
import messages from './messages';
import staticStyles from './style';

interface RepayScreenWrapperProps {
  title: string;
  currencySymbol: string;
  currentBorrows: string;
  currentBorrowsInUSD: string;
  walletBalance: string;
  walletBalanceInUSD: string;
  totalCollateralUSD: string;
  totalCollateralMarketReferenceCurrency: string;
  healthFactor: string;
  loanToValue: string;
  children: ReactNode;
}

export default function RepayScreenWrapper({
  title,
  currencySymbol,
  currentBorrows,
  currentBorrowsInUSD,
  walletBalance,
  walletBalanceInUSD,
  totalCollateralUSD,
  totalCollateralMarketReferenceCurrency,
  healthFactor,
  loanToValue,
  children,
}: RepayScreenWrapperProps) {
  const intl = useIntl();
  const { networkConfig } = useProtocolDataContext();

  return (
    <>
      <RepayWithdrawWrapper className="RepayScreenWrapper" title={title}>
        <div className="RepayScreenWrapper__items-wrapper">
          <Row title={intl.formatMessage(messages.youBorrowed)} weight="light">
            <Value
              value={Number(currentBorrows)}
              subValue={Number(currentBorrowsInUSD)}
              symbol={currencySymbol}
              subSymbol="USD"
              maximumValueDecimals={4}
              minimumValueDecimals={1}
              maximumSubValueDecimals={2}
              minimumSubValueDecimals={2}
            />
          </Row>

          <Row title={intl.formatMessage(messages.walletBalance)} weight="light">
            <Value
              value={Number(walletBalance)}
              subValue={Number(walletBalanceInUSD)}
              symbol={currencySymbol}
              subSymbol="USD"
              maximumValueDecimals={4}
              minimumValueDecimals={1}
              maximumSubValueDecimals={2}
              minimumSubValueDecimals={2}
            />
          </Row>
        </div>

        <div className="RepayScreenWrapper__items-wrapper">
          <Row title={intl.formatMessage(messages.yourCollateral)} weight="light">
            <Value
              value={Number(totalCollateralUSD)}
              subValue={
                !networkConfig.usdMarket
                  ? Number(totalCollateralMarketReferenceCurrency)
                  : undefined
              }
              symbol="USD"
              subSymbol="ETH"
              maximumValueDecimals={2}
              minimumValueDecimals={2}
              maximumSubValueDecimals={4}
              minimumSubValueDecimals={1}
            />
          </Row>

          <CollateralCompositionBar isColumn={true} />
        </div>

        <div className="RepayScreenWrapper__items-wrapper">
          <HealthFactor value={healthFactor} titleLightWeight={true} />
          <Row
            title={
              <MaxLTVHelpModal text={intl.formatMessage(messages.loanToValue)} lightWeight={true} />
            }
            weight="light"
          >
            <ValuePercent value={loanToValue} />
          </Row>
        </div>
      </RepayWithdrawWrapper>

      <ContentWrapper
        withFullHeight={true}
        withBackButton={true}
        className="RepayScreenWrapper__content"
      >
        {children}
      </ContentWrapper>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
