import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useThemeContext, BasicModal } from '@aave/aave-ui-kit';

import { useStaticPoolDataContext } from 'libs/pool-data-provider';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from 'components/RouteParamsValidationWrapper';
import { getLPTokenPoolLink } from 'helpers/lp-tokens';
import Link from 'components/basic/Link';
import ScreenWrapper from 'components/wrappers/ScreenWrapper';
import NoDataPanel from 'components/NoDataPanel';
import ReserveInformation from '../../components/ReserveInformation';
import UserInformation from '../../components/UserInformation';
import messages from './messages';
import staticStyles from './style';
import linkIcon from 'images/blueLinkIcon.svg';
import { getAssetInfo } from 'helpers/config/assets-config';
import { useCallback } from 'react';

function ReserveOverview({
  poolReserve,
  userReserve,
  currencySymbol,
  walletBalance,
  user,
}: ValidationWrapperComponentProps) {
  const intl = useIntl();
  const history = useHistory();
  const { currentTheme, sm } = useThemeContext();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const asset = getAssetInfo(currencySymbol);

  const poolLink = getLPTokenPoolLink({
    symbol: currencySymbol,
    underlyingAsset: poolReserve.underlyingAsset,
  });

  const backdropPressHandler = useCallback(() => {
    history.push('/markets');
  }, [history]);

  return (
    <>
      <BasicModal
        className="ReserveModal"
        isVisible
        onBackdropPress={backdropPressHandler}
        withCloseButton={true}
      >
        <ScreenWrapper
          pageTitle={intl.formatMessage(messages.pageTitle, {
            currencySymbol: asset.formattedName,
          })}
          className="ReserveOverview"
          withMobileGrayBg={true}
        >
          <div className="ReserveOverview__content">
            <div className="ReserveOverview__mobileUserInformation-wrapper">
              {user ? (
                <UserInformation
                  symbol={currencySymbol}
                  user={user}
                  poolReserve={poolReserve}
                  userReserve={userReserve}
                  walletBalance={walletBalance}
                />
              ) : (
                <NoDataPanel
                  title={intl.formatMessage(messages.noConnectWalletCaption)}
                  description={intl.formatMessage(messages.noConnectWalletDescription)}
                  withConnectButton={true}
                  className="ReserveOverview__noDataPanel"
                />
              )}
            </div>

            {sm && poolLink && (
              <div className="ReserveOverview__poolLink-inner">
                <p>
                  {intl.formatMessage(messages.provideLiquidity, {
                    here: (
                      <Link
                        to={poolLink}
                        title={intl.formatMessage(messages.here)}
                        absolute={true}
                        inNewWindow={true}
                        bold={true}
                        color="secondary"
                      />
                    ),
                  })}
                </p>
                <img src={linkIcon} alt="" />
              </div>
            )}

            <div className="ReserveOverview__content-wrapper">
              <ReserveInformation
                poolReserve={poolReserve}
                marketRefPriceInUsd={marketRefPriceInUsd}
                symbol={currencySymbol}
              />
            </div>
          </div>
        </ScreenWrapper>
      </BasicModal>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .ReserveOverview {
          &__information-title,
          &__poolLink-inner {
            color: ${currentTheme.textDarkBlue.hex};
          }
          &__noUser {
            background: ${currentTheme.whiteElement.hex} !important;
          }
        }
      `}</style>
    </>
  );
}

export default routeParamValidationHOC({
  withWalletBalance: true,
})(ReserveOverview);
