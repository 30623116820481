import { useState, useEffect, useCallback } from 'react';
import { ChainId } from '@aave/contract-helpers';

import { getProvider } from 'helpers/config/markets-and-network-config';

const mainnetProvider = getProvider(ChainId.arbitrum_rinkeby);

const useGetEnsName = (address: string) => {
  const [ensName, setEnsName] = useState<string | null>(null);

  const getRecord = useCallback(
    async (address: string) => {
      try {
        const name = await mainnetProvider.lookupAddress(address);
        setEnsName(name);
      } catch (error) {
        console.error('ENS lookup error', error);
      }
    },
    [address, setEnsName]
  );

  useEffect(() => {
    if (address) {
      getRecord(address);
      return;
    }

    setEnsName(null);
  }, [address]);

  return { ensName };
};

export default useGetEnsName;
