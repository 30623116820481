import { useIntl } from 'react-intl';

import { CompactNumber } from 'components/basic/CompactNumber';
import TextWithTooltip from 'components/TextWithTooltip';
import messages from './messages';
import staticStyles from './style';

interface TotalPlatformFeesProps {
  value: number;
}

export default function TotalPlatformFees({ value }: TotalPlatformFeesProps) {
  const intl = useIntl();

  return (
    <div className="TotalPlatformFees">
      <div className="TotalPlatformFees__title-container">
        <p>{intl.formatMessage(messages.title)}</p>
        <TextWithTooltip text={''} iconSize={14} id="TotalPlatformFees">
          <p>{intl.formatMessage(messages.description)}</p>
        </TextWithTooltip>
      </div>
      <h2 className="TotalPlatformFees--big">
        $ <CompactNumber value={value} maximumFractionDigits={2} minimumFractionDigits={2} />
      </h2>

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .TotalPlatformFees {
          color: #131313;
        }
      `}</style>
    </div>
  );
}
