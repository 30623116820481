import { useIntl } from 'react-intl';

import { useRdntPrices } from 'libs/aave-protocol-js/hooks/use-rdnt-prices';
import { useRdntethVestable } from 'libs/aave-protocol-js/hooks/use-rdnteth-vestable';
import { useRdntethStake } from 'libs/aave-protocol-js/hooks/use-rdnteth-stake';
import { useApiDataProviderContext } from 'libs/api-data-provider/ApiDataProvider';
import { useRdntethTokenInfo } from 'libs/aave-protocol-js/hooks/use-rdnteth-token-info';
import { TopStats } from 'modules/manage-radiant/components/TopStats';
import GradientLine from 'components/basic/GradientLine';
import DefaultButton from 'components/basic/DefaultButton';
import ContentWrapperWithTopLine from 'components/wrappers/ContentWrapperWithTopLine';
import staticStyles from './style';
import Link from 'components/basic/Link';
import LoadingSpinner from 'components/LoadingSpinner';
import { isEmpty } from 'helpers/utility';

export default function StakeTopPanel() {
  const intl = useIntl();

  const { availableForVesting } = useRdntethVestable();
  const { userStakedAmount } = useRdntethStake();
  const { pool2Info } = useApiDataProviderContext();
  const { tokenInfo } = useRdntethTokenInfo();
  const { prices } = useRdntPrices();

  const userStakedUsdVal = Number(userStakedAmount) * Number(prices?.lpTokenPrice || 0);
  const userStakedPercent = Number(userStakedAmount) / Number(tokenInfo.totalSupply);
  const rdntPrice = Number(prices.tokenPrice);

  const dailyTotalRdntEmitted = (pool2Info.pool2RewardsPerSecond || 0) * 86400;
  const dailyUserRdntEmitted = dailyTotalRdntEmitted * userStakedPercent;
  const dailyUsdVal = dailyTotalRdntEmitted * rdntPrice;

  const apr = pool2Info.apr;
  const yourDailyRevenue = dailyUsdVal * userStakedPercent;

  return (
    <div className="DepositBorrowTopPanel StakeTopPanel">
      <div className="ManageRadiant__content-item">
        <div className="ManageRadiantMain__top">
          <ContentWrapperWithTopLine title="" className="ManageRadiantMain__top-revenue">
            <LoadingSpinner loading={isEmpty(prices) || isEmpty(pool2Info) || isEmpty(tokenInfo)} />
            <div className="ManageRadiantMain__revenue-item">
              <TopStats
                title={`Your Staked LP Value`}
                infoText={
                  'Your aggregated balance shows the approximate value in USD of your deposited RDNT/WETH LP tokens. The displayed value fluctuates based on the current market value of the corresponding tokens.'
                }
                value={userStakedUsdVal ? userStakedUsdVal : 0}
                dollarPrefix={true}
                showFullNum={true}
              />
              <div className="data-grid">
                <div className="col">
                  <p>Staked LP Tokens:</p>
                  <p>Share of Pool:</p>
                </div>
                <div className="col numberic">
                  <p>
                    {intl.formatNumber(Number(userStakedAmount), {
                      maximumFractionDigits: 2,
                    })}
                  </p>
                  <p>
                    {intl.formatNumber(Number(userStakedPercent) * 100, {
                      maximumFractionDigits: 2,
                    })}
                    %
                  </p>
                </div>
              </div>
            </div>

            <GradientLine size={1} direction="vertical" />

            <div className="ManageRadiantMain__revenue-item">
              <TopStats
                title={`APR`}
                infoText={
                  'Stakers receive RDNT emissions as an incentive for providing liquidity to the protocol.'
                }
                value={apr ? apr * 100 : 0}
                dollarPrefix={false}
                showFullNum={false}
                isPercent={true}
                hideTokenImg={true}
              />
              <div className="data-grid">
                <div className="col">
                  <p>Global Daily Rewards:</p>
                  <p>Your Daily Rewards:</p>
                </div>
                <div className="col numberic">
                  <p>
                    {' '}
                    {intl.formatNumber(Number(dailyTotalRdntEmitted), {
                      maximumFractionDigits: 2,
                    })}{' '}
                  </p>
                  <p>
                    {' '}
                    {intl.formatNumber(Number(dailyUserRdntEmitted), {
                      maximumFractionDigits: 2,
                    })}{' '}
                  </p>
                </div>
              </div>
            </div>

            <GradientLine size={1} direction="vertical" />

            <div className="ManageRadiantMain__revenue-item green">
              <TopStats
                title={`Your Daily Revenue`}
                infoText={
                  'Your daily revenue earned from staking RDNT/WETH LP tokens. This value will fluctuate based on the market value of RDNT.'
                }
                value={yourDailyRevenue}
                dollarPrefix={true}
                showFullNum={true}
              >
                <div className="data-grid">
                  <div className="col">
                    <p>Monthly Revenue:</p>
                    <p>Yearly Revenue:</p>
                  </div>
                  <div className="col numberic">
                    <p>
                      $
                      {intl.formatNumber(Number(yourDailyRevenue) * 30, {
                        maximumFractionDigits: 2,
                      })}
                    </p>
                    <p>
                      $
                      {intl.formatNumber(Number(yourDailyRevenue) * 365, {
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>
              </TopStats>
            </div>

            <GradientLine size={1} direction="vertical" />

            <div className="ManageRadiantMain__revenue-item">
              <TopStats
                title={`Earned RDNT Rewards`}
                infoText={
                  'A running tally of RDNT earned from staking your RDNT/WETH LP tokens. Vested Radiant can be viewed on the Manage Radiant page.'
                }
                value={Number(availableForVesting)}
                dollarPrefix={false}
                showFullNum={true}
                subValue={
                  prices.tokenPrice ? Number(availableForVesting) * prices.tokenPrice : undefined
                }
              >
                <div className="Vesting_Button">
                  <Link
                    to={`/staking/${`rdntweth`}/claim/confirmation?amount=-1`}
                    className="StakingWrapper__link ButtonLink"
                  >
                    <DefaultButton
                      title={'Start Vesting'}
                      className="StakingWrapper__button"
                      color="purple"
                      size="big"
                      onClick={() => {
                        console.log('Vest');
                      }}
                    />
                  </Link>
                </div>
              </TopStats>
            </div>
          </ContentWrapperWithTopLine>
        </div>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
